import React from "react";
import {Company, User} from "hgr-api";
import {Button, Col, Container, Form, Modal, Row} from "react-bootstrap";
import "./user-profile.scss";
import {UserService} from "../../../services/user-service";
import {CompanyService} from "../../../services/company-service";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import {UploadImage} from "../../shared/upload-image/UploadImage";

type UserProfileProps = {
    user: User,
    company: Company,
    showProfile: boolean,
    onSave?: () => void,
    onClose?: () => void,
};
type UserProfileState = {
    name?: string,
    nickName?: string,
    picture?: string,
};


class UserProfile extends React.Component<UserProfileProps, UserProfileState> {

    userService: UserService = new UserService();
    companyService: CompanyService = new CompanyService();

    constructor(props: UserProfileProps) {
        super(props);
        this.state = this.getDefaultState();
    }

    private getDefaultState() {
        return {name: this.props.user.name, nickName: this.props.user.nickName, picture: this.props.user.picture};
    }

    render() {
        return (

            <Modal className={"dark"}
                   dialogClassName={classNames("in-app", "settings", "bg-user-profile")}
                   show={this.props.showProfile}
                   onHide={() => this.handleClose()}
                   centered={true}
            >
                <Modal.Header>
                    <h1 className="roomContainerHeading">Modifier mon profil</h1>
                </Modal.Header>
                <Modal.Body>

                    <Container className="user-profile">
                        <Row className="justify-content-md-center">
                            <Col md={3} className={"text-center"}>
                                {this.displayUserPicture()}
                                <div>
                                    <a href={"https://avatarmaker.com/"} target={'_blank'} rel={"noreferrer"}>
                                        Créer votre avatar <FontAwesomeIcon icon={faExternalLinkAlt}/>
                                    </a>
                                </div>
                            </Col>
                            <Col>
                                <Form>
                                    <Form.Group as={Row} controlId="userForm.login">
                                        <Form.Label column md={2}>Nom</Form.Label>
                                        <Col>
                                            <Form.Control type="text"
                                                          onChange={(e) => this.setState({ name: e.target.value })}
                                                          value={this.state.name} placeholder="Nom"/>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="userForm.name">
                                        <Form.Label column md={2}>Alias</Form.Label>
                                        <Col>
                                            <Form.Control type="text"
                                                          onChange={(e) => this.setState({ nickName: e.target.value })}
                                                          value={this.state.nickName} placeholder="Alias"/>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column md={2}>Email</Form.Label>
                                        <Col>
                                            <Form.Control plaintext readOnly type="text" value={this.props.user.email}/>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column md={2}>Bureau</Form.Label>
                                        <Col>
                                            <Form.Control plaintext readOnly type="text" value={this.getOfficeName()}/>
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"link"} onClick={() => this.handleClose()}>Fermer</Button>
                    <Button onClick={() => this.saveModification()}
                            disabled={!this.profileChanged()}>Appliquer</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    private handleClose() {
        this.setState(this.getDefaultState());
        return this.props.onClose?.();
    }

    saveModification(): void {
        if (this.profileChanged()) {
            let edition: User = {
                ref: this.props.user.ref,
                name: this.state.name,
                picture: this.state.picture,
                nickName: this.state.nickName
            };
            this.userService.updateUser(edition).then(() => {
                this.props.onSave?.();
            });
        }
    }

    displayUserPicture() {
        let avatar = this.userService.getUserAvatar(this.props.user);

        // https://avatarmaker.com/
        return (

            <UploadImage onChange={newImageAsB64DataUrl => this.setState({picture: newImageAsB64DataUrl})}
                         imageUrl={avatar}
                         onMaxSizeReached={(s, m) => console.log(`${s} > ${m}`)}/>

        );
    }

    private getOfficeName() {
        let company = this.props.company;
        let officeRef = this.props.user.office;
        let office = company.sites?.flatMap(s => s.rooms).find(r => r?.ref === officeRef);
        return office?.name;
    }

    private profileChanged() {
        let nameChanged = this.state.name !== this.props.user.name;
        let nickNameChanged = this.state.nickName !== this.props.user.nickName;
        let pictureChanged = this.state.picture !== this.props.user.picture;
        return nameChanged || nickNameChanged || pictureChanged;
    }
}

export default UserProfile;
