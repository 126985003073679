export const AppConfig = {
  VERSION: process.env.VERSION,
  ENVIRONMENT: {
    apiUrl: "/hgr",
    socialLogin: false,
    mediaView: true,
    jitsiView: true,
    webconfDomain: "meet.jit.si",
    useJwt: false,
    pictureMaxSizeInKb: 500,
    tcuVersion: "1.0",
    inseeApiUrl: "https://api.insee.fr/entreprises/sirene/V3",
    inseeToken: "7c90eba7-cf2f-3a48-8e4e-a963973f49cd"
  }
};

export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER'
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error'
};

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_LOCAL_DATETIME_FORMAT_Z = 'YYYY-MM-DDTHH:mm Z';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';

export const HGR_CUSTOM_HEADER_JWT = "x-hgr-jwt"
export const STORAGE_AUTH_TOKEN_KEY = 'hgr-authToken';
export const STORAGE_HGR_USER_KEY = "hgr.user";
export const STORAGE_HGR_AUTH_KEY = "hgr.auth";
export const ACTION_TYPES = {
  LOGIN: 'authentication/LOGIN',
  GET_SESSION: 'authentication/GET_SESSION',
  LOGOUT: 'authentication/LOGOUT',
  CLEAR_AUTH: 'authentication/CLEAR_AUTH',
  ERROR_MESSAGE: 'authentication/ERROR_MESSAGE'
};

export const TOAST_ONBOARDING_ID = "10001";

export const EMAIL_CONSTRAINTS = {
  email: {
    email: true,
    message: "^Veuillez entrer une adresse mail valide."
  }
};