import React from 'react';
import "./index.scss"

class SeeYouSoon extends React.Component {

    render() {
        return (
            <div className="see-you-soon">
                <div className="veil">
                    <h1>Put a promotional message here</h1>
                    <h1>
                        <a href={"/"}>
                            Retourner à l'accueil
                        </a>
                    </h1>
                </div>
            </div>
        );
    }
}

export default SeeYouSoon;