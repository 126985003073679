import React, {Component, Fragment} from 'react';
import {Route} from "react-router-dom";
import LoginPage from "./components/login/LoginPage";
import PrivateRoute from "./components/utils/PrivateRoute";
import HgrHome from "./components/home/HgrHome";
import CompanyCreation from "./components/onboarding/CompanyCreation";
import CompanySiteCreation from "./components/onboarding/CompanySiteCreation";
import RoomSearch from "./components/onboarding/search-rooms/RoomSearch";
import RoomCreation from "./components/onboarding/RoomCreation";
import ColleaguesInvitation from "./components/onboarding/ColleaguesInvitation";
import Confirmation from "./components/onboarding/confirmation/Confirmation";
import WaitingApproval from "./components/onboarding/waiting-approval/WaitingApproval";
import HgrAccessDenied from "./components/access-denied/HgrAccessDenied";
import HgrWelcome from "./components/onboarding/welcome/HgrWelcome";
import SeeYouSoon from "./components/see-you-soon/SeeYouSoon";
import OfficeAssignmentRequest from "./components/mail-link/OfficeAssignmentRequest";
import LostInSpace from "./components/lost-in-space/LostInSpace";
import WelcomeInvitee from "./components/onboarding/WelcomeInvitee";
import {LinkedInCallback} from "react-linkedin-login-oauth2";


export default class HgrRoutes extends Component {
    render() {
        return (
            <Fragment>
                <Route component={LoginPage} path="/login"/>
                <Route component={LinkedInCallback} exact path="/linkedin"/>
                <Route component={HgrAccessDenied} path="/access-denied" />
                <Route component={SeeYouSoon} path="/see-you-soon" />
                <Route component={LostInSpace} path="/lost-in-space" />

                <PrivateRoute component={HgrWelcome} path="/welcome"/>

                <PrivateRoute component={WelcomeInvitee}
                              path="/welcome-invitee"
                              loginMessage={"Veuillez vous authentifier afin d'accepter l'invitation"}
                />
                <PrivateRoute component={HgrHome} path='/home' />
                <PrivateRoute component={OfficeAssignmentRequest} path='/office-request' />

                { /* On Boarding URLS */}
                <PrivateRoute component={CompanyCreation} path='/boarding/company'/>
                <PrivateRoute component={CompanySiteCreation} path='/boarding/site'/>
                <PrivateRoute component={RoomSearch} path='/boarding/search-room'/>
                <PrivateRoute component={RoomCreation} path='/boarding/room'/>
                <PrivateRoute component={ColleaguesInvitation} path='/boarding/invite'/>
                <PrivateRoute component={Confirmation} path='/boarding/confirm'/>
                <PrivateRoute component={WaitingApproval} path='/boarding/waiting-approval'/>
            </Fragment>
        );
    }
}
