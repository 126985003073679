import React, {Component} from "react";
import {Popover} from "react-bootstrap";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type ExternalInvitationProps = {
    vroomName: string,
    vroomLink: string,
    vroomPassword?: string,
};
type ExternalInvitationState = {
    vroomLinkCopied: boolean,
    vroomPasswordCopied: boolean,
    fullCopy: boolean,
};

export class ExternalInvitation extends Component<ExternalInvitationProps, ExternalInvitationState> {

    constructor(props: ExternalInvitationProps) {
        super(props);
        this.state = {vroomLinkCopied: false, vroomPasswordCopied: false, fullCopy: false};
    }

    render() {
        let fullText = `Rejoins moi dans ma salle virtuelle '${this.props.vroomName}'\nLien: ${this.props.vroomLink}\nPassword: ${this.props.vroomPassword}`;
        return (<Popover id="popover-invitation">
            <Popover.Title as="h3">Inviter des utilisateurs externes dans <b>{this.props.vroomName}</b></Popover.Title>
            <Popover.Content>
                <p>Lors d'une invitation externe, l'invité ne pourra utiliser les fonctionnalités de Hologramme.</p>
                <h4>Lien de la vidéo salle</h4>
                <CopyToClipboard text={this.props.vroomLink}
                                 onCopy={() => this.setState({
                                     vroomLinkCopied: true,
                                     vroomPasswordCopied: false,
                                     fullCopy: false
                                 })}>
                    <pre>{this.props.vroomLink}</pre>
                </CopyToClipboard>
                {this.state.vroomLinkCopied && <span><FontAwesomeIcon icon={faCheck}/>Copié!</span>}
                {this.props.vroomPassword && <>
                    <h4>Mot de passe</h4>
                    <CopyToClipboard text={this.props.vroomPassword}
                                     onCopy={() => this.setState({
                                         vroomPasswordCopied: true,
                                         vroomLinkCopied: false,
                                         fullCopy: false
                                     })}>
                        <pre>{this.props.vroomPassword}</pre>
                    </CopyToClipboard>
                    {this.state.vroomPasswordCopied && <span><FontAwesomeIcon icon={faCheck}/>Copié!</span>}
                </>}
                <p>
                    <CopyToClipboard text={fullText}
                                     onCopy={() => this.setState({
                                         vroomLinkCopied: false,
                                         vroomPasswordCopied: false,
                                         fullCopy: true
                                     })}>
                        <button>Copier tout</button>
                    </CopyToClipboard>
                    {this.state.fullCopy && <span><FontAwesomeIcon icon={faCheck}/>Copié!</span>}
                </p>
            </Popover.Content>
        </Popover>);
    }

}