import './room-settings.scss'

import React from "react";
import {Button, Col, Container, Form, FormControl, Modal, Row} from "react-bootstrap";
import {LoggerFactory} from "../../utils/ConfigLog4j";
import {Logger} from "typescript-logging";
import classNames from "classnames";
import {
    CompanyRoom,
    CompanyRoomStateEnum,
    RoomConfig,
    RoomConfigCameraModeEnum,
    RoomConfigNameModeEnum,
    RoomConfigTypeEnum
} from "hgr-api";
import Switch from "react-switch";
import {CompanyService} from "../../../services/company-service";
import RoomOccupants from "./RoomOccupants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCog,
    faLock,
    faMicrophone,
    faMicrophoneSlash,
    faUnlock,
    faVideo,
    faVideoSlash
} from "@fortawesome/free-solid-svg-icons";

type Props = {
    room: CompanyRoom
}

type State = RoomConfig & {
    roomRef?: string,
    roomName?: string,
    showSettings: boolean,
    locked?: boolean,
}

export default class ConfigureRoom extends React.Component<Props, State> {
    private logger: Logger = LoggerFactory.getLogger("hgr.ConfigureRoom");
    private companyService: CompanyService = new CompanyService();

    constructor(props: Props) {
        super(props);
        this.state = this.readStateFromRoom(props.room);
    }

    render() {
        const {roomName, cameraMode, nameMode, startWithMicOn, locked} = this.state;
        return (
            <React.Fragment>
                <Button variant={"light"} className={"btn-icon"} onClick={() => this.showSettings(true)}>
                    <FontAwesomeIcon icon={faCog}/>
                </Button>

                <Modal
                    show={this.state.showSettings}
                    className={"dark"}
                    dialogClassName={classNames("in-app", "room-settings", "settings", this.getBackgroundClass(this.props.room.config?.type))}
                    centered={true}
                >
                    <Modal.Header>
                        <h1 className="roomContainerHeading">Personnaliser la salle</h1>
                    </Modal.Header>
                    <Modal.Body>
                        <Container className={"h-100"}>
                            <div className={"modal-content"}>
                                <Col>
                                    <div className="roomContainerBox">
                                        <Form.Group className="optionsRow">
                                            <FormControl type="text" className="room-name"
                                                         placeholder={"Nom de votre salle"}
                                                         required
                                                         isInvalid={!roomName}
                                                         value={roomName}
                                                         onChange={(e) => this.setState({roomName: e.target.value})}
                                            />
                                        </Form.Group>
                                        <Row className={"flex-grow-1"}>
                                            <Col>
                                                {this.renderRoomConfigOptions(locked, cameraMode, startWithMicOn, nameMode)}
                                            </Col>
                                            <Col>
                                                <div className={"roomContainerBox"}>
                                                    {
                                                        this.props.room.owner &&
                                                        <>
                                                            <div className={"owner-line"}>
                                                                <span className="options">Propriétaire</span>
                                                                <span className="options text-right">
                                                                    {this.props.room.owner?.name}
                                                                </span>
                                                            </div>
                                                            <hr className={"bg-white"}/>
                                                        </>
                                                    }
                                                    {
                                                        this.props.room.occupants && this.props.room.occupants.length > 0 &&
                                                        <RoomOccupants room={this.props.room}/>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </div>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant={"link"} onClick={() => this.showSettings(false)}>Fermer</Button>
                        <Button onClick={() => this.applyChanges()}>Appliquer</Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        );
    }

    private renderRoomConfigOptions(locked: boolean | undefined, cameraMode: RoomConfigCameraModeEnum | undefined, startWithMicOn: boolean | undefined, nameMode: RoomConfigNameModeEnum | undefined) {
        return <>
            <div className="optionsRow">
                <span className="options">Fermer la porte</span>
                <Switch
                    onChange={(checked => this.setState({locked: checked}))}
                    width={60}
                    height={30}
                    handleDiameter={24}
                    offColor="#888"
                    onColor="#07a79d"
                    checked={locked ?? false}
                    checkedIcon={<FontAwesomeIcon icon={faLock}/>}
                    uncheckedIcon={<FontAwesomeIcon icon={faUnlock}/>}
                />
            </div>
            <div className="optionsRow">
                <span className="options">Camera activée à l'entrée</span>
                <Switch
                    onChange={(checked => this.setState({cameraMode: checked ? RoomConfigCameraModeEnum.Camera : RoomConfigCameraModeEnum.None}))}
                    width={60}
                    height={30}
                    handleDiameter={24}
                    offColor="#888"
                    onColor="#07a79d"
                    checked={cameraMode === RoomConfigCameraModeEnum.Camera}
                    checkedIcon={<FontAwesomeIcon icon={faVideo}/>}
                    uncheckedIcon={<FontAwesomeIcon icon={faVideoSlash}/>}
                />
            </div>
            <div className="optionsRow">
                <span className="options">Micro allumé à l'entrée</span>
                <Switch
                    onChange={(checked => this.setState({startWithMicOn: checked}))}
                    width={60}
                    height={30}
                    handleDiameter={24}
                    offColor="#888"
                    onColor="#07a79d"
                    checked={startWithMicOn ?? false}
                    checkedIcon={<FontAwesomeIcon icon={faMicrophone}/>}
                    uncheckedIcon={<FontAwesomeIcon icon={faMicrophoneSlash}/>}
                />
            </div>
            <div className="optionsRow">
                <span className="options">Afficher l'alias des membres</span>
                <Switch
                    onChange={(checked => this.setState({nameMode: checked ? RoomConfigNameModeEnum.Alias : RoomConfigNameModeEnum.Name}))}
                    width={60}
                    height={30}
                    handleDiameter={24}
                    offColor="#888"
                    onColor="#07a79d"
                    checked={nameMode === RoomConfigNameModeEnum.Alias}
                />
            </div>
        </>;
    }

    private showSettings(show: boolean) {
        this.logger.info(`Showing setting dialog for room : ${show}`);
        let newState = {} as State;
        if (show) {
            // Set state to new state
            let room = this.props.room;
            newState = this.readStateFromRoom(room);
        }
        newState.showSettings = show;
        this.setState(newState);
    }

    // noinspection JSMethodCanBeStatic
    private readStateFromRoom(room: CompanyRoom): State {
        return {
            roomRef: room.ref,
            roomName: room.name,
            locked: room.state === CompanyRoomStateEnum.Locked,
            ...room.config
        } as State;
    }

    private getBackgroundClass(type: RoomConfigTypeEnum | undefined) {
        let className = "bg-default";
        if (type) {
            switch (type) {
                case RoomConfigTypeEnum.BreakRoom:
                    className = "bg-breakroom";
                    break;
                case RoomConfigTypeEnum.Office:
                    className = "bg-office";
                    break;
                case RoomConfigTypeEnum.OpenSpace:
                    className = "bg-openspace";
                    break;
                case RoomConfigTypeEnum.MeetingRoom:
                    className = "bg-meetingroom";
                    break;
                case RoomConfigTypeEnum.Conference:
                    className = "bg-conference";
                    break;
                case RoomConfigTypeEnum.Forum:
                    className = "bg-forum";
                    break;
                case RoomConfigTypeEnum.Lobby:
                    className = "bg-lobby";
                    break;
                default:
                    this.logger.error("background Classname is not defined for room type: " + type);
            }
        } else {
            this.logger.warn("Room type is undefined for room: " + this.props.room.name)
        }
        return className;
    }

    private async applyChanges() {
        let room = this.props.room;
        let roomUpdate: CompanyRoom = {
            ref: room.ref,
            companyRef: room.companyRef,
            siteRef: room.siteRef,
            name: this.state.roomName,
            state: this.state.locked ? CompanyRoomStateEnum.Locked : CompanyRoomStateEnum.Opened,
            config: {
                startWithMicOn: this.state.startWithMicOn,
                nameMode: this.state.nameMode,
                cameraMode: this.state.cameraMode,
                viewMode: this.state.viewMode
            }
        };
        await this.companyService.updateCompanyRoom(roomUpdate);
        this.setState({
            showSettings: false
        });
    }
}