import React, {Component} from 'react';

import SocialLogin, {Props} from 'react-social-login';
import {Button} from "react-bootstrap";
import {faFacebook, faGoogle, faLinkedin} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {LinkedIn} from "react-linkedin-login-oauth2";
import {SocialUserProfile} from "../model/hgr-user";

class GoogleButton extends Component<Props> {

    render() {
        return (
            <Button color="google plus" onClick={this.props.triggerLogin} {...this.props}>
                <FontAwesomeIcon icon={faGoogle}/> Google
            </Button>
        );
    }
}

class FacebookButton extends Component<Props> {

    render() {
        return (
            <Button color="facebook" onClick={this.props.triggerLogin} {...this.props}>
                <FontAwesomeIcon icon={faFacebook}/> Facebook
            </Button>
        );
    }
}

class LinkedinButton extends Component<Props> {

    render() {
        return (<LinkedIn scope={"r_liteprofile&r_emailaddress&w_member_social"}
                          clientId={this.props.appId}
                          redirectUri={`${window.location.origin}/linkedin`}
                          onSuccess={(code) => {
                              if (this.props.onLoginSuccess) {
                                  let sl_user : SocialUserProfile = {
                                      provider: "linkedin",
                                      token: {
                                          idToken: code,
                                          expiresAt: new Date().getTime() + 30 * 60000,
                                          accessToken: code,
                                      }
                                  }
                                  this.props.onLoginSuccess(sl_user);
                              }

                          }}
                          onError={(error) => {
                              if (this.props.onLoginFailure) {
                                  this.props.onLoginFailure(error);
                              }
                          }}
            >
                {({linkedInLogin}) => (
                    <Button block color="linkedin" onClick={linkedInLogin}>
                        <FontAwesomeIcon icon={faLinkedin}/> Linkedin
                    </Button>
                )}
            </LinkedIn>
        );
    }
}


export const GoogleLoginButton = SocialLogin(GoogleButton);
export const FacebookLoginButton = SocialLogin(FacebookButton);
export const LinkedinLoginButton = LinkedinButton;
