import React, {Component} from "react";
import "./style.scss";
import {Button} from "react-bootstrap";
import {Step} from "./Step";

import { Link } from "react-router-dom";

const steps = [
    {
        title: "Enregistrez votre entreprise",
        description: "Hologramme est une représentation virtuelle de votre lieu de travail. " +
            "Vous enregistrez d'abord votre entreprise avec son nom, son Siren et son logo."
    },
    {
        title: "Enregistrez le lieu géographique",
        description: "Pour plus d'immersion, vous enregistrer le lieu géographique de votre entreprise. " +
            "Certaines entreprises ont plusieurs sites, vos collègues pourront enregistrer les leurs aussi."
    },
    {
        title: "Définissez votre bureau",
        description: "Que vous travailliez en Openspace ou dans un bureau individuel, vous enregistrer votre bureau. " +
            "Vous pourrez ensuite rendre visite à vos collègues dans leurs bureaux, prendre une pause avec eux, ou aller " +
            "dans des salles de réunions."
    },
    {
        title: "Invitez des collègues à vous rejoindre",
        description: "Peuplez votre entreprise en invitant vos collègues qui pourront eux aussi créer leurs propres salles. " +
            "Vous pourrez toujours inviter des personnes externes à l'entreprise à vous rejoindre dans vos salles."
    },
    ];


export default class Steps extends Component {
    render() {
        return (
            <div>
                <h1 className={"text-center"}>Comment ça marche ?</h1>
                {
                    steps.map((s, idx) => <Step key={idx} inverted={idx % 2 === 0} title={s.title} description={s.description}/>)
                }

                <div className="centerView p-5">
                    <Link to={'/boarding/company'}>
                        <Button variant={"primary"} className="btn-lg">
                            Enregistrer mon entreprise
                        </Button>
                    </Link>
                </div>
            </div>
        );
    }
}
