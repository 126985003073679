import { LoggerFactory } from "components/utils/ConfigLog4j";
import {UserInvitation} from "hgr-api";
import API from "./api-service";

export class InvitationService {
  
  private logger = LoggerFactory.getLogger("hgr.InvitationService");

  /**
   * invite
   */
  public async invite(invitees: UserInvitation[]): Promise<void> {
    this.logger.info(() => "Invite colleagues: " + JSON.stringify(invitees));
    let api = API.getUserApi();
    if (invitees.some(i => !!i.email)) {
      await api.invite({invitations: invitees});
    }
  }
}