import React, {Component} from 'react';
import {AgGridColumn, AgGridReact} from "@ag-grid-community/react";
import {AllCommunityModules, ValueGetterFunc, ValueGetterParams} from "@ag-grid-community/all-modules";

import {CompanyRoom, UserInvitation, UserInvitationTypeEnum} from "hgr-api";
import classNames from "classnames";
import {Button, Col, Form} from "react-bootstrap";
import validate from "validate.js";
import {EMAIL_CONSTRAINTS} from "../../config/constants";
import {InvitationService} from "../../../services/invitation-service";
import AvatarRenderer from "./AvatarRenderer";
import {UserService} from "../../../services/user-service";
import {faShareAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type Props = {
    room: CompanyRoom,
    invitations?: UserInvitation[],
}

type State = {
    inviteeFormErrors?: string,
    inviteeEmail: string,
    inviteeName: string,
}

class RoomOccupants extends Component<Props, State> {

    private userService: UserService = new UserService();
    private invitationService: InvitationService = new InvitationService();

    constructor(props: Props) {
        super(props);
        this.state = {
            inviteeEmail: "",
            inviteeName: "",
        }
    }

    private userAvatarUrlGetter: ValueGetterFunc = (params: ValueGetterParams) => {
        let user = params.data;
        return this.userService.getUserAvatar(user);
    }

    render() {
        return (
            <>
                <div className={classNames("occupants", "ag-theme-alpine-dark")}>
                    <div>
                        <span className="options">Occupants</span>
                    </div>
                    <AgGridReact
                        modules={AllCommunityModules}
                        frameworkComponents={{
                            "avatarRenderer": AvatarRenderer
                        }}
                        // setting default column properties
                        defaultColDef={{
                            resizable: true,
                            sortable: true,
                            filter: true,
                        }}

                        rowData={this.props.room.occupants}>
                        <AgGridColumn headerName=""
                                      valueGetter={this.userAvatarUrlGetter}
                                      cellRenderer="avatarRenderer"
                                      field="picture"
                                      width={50}
                                      resizable={false}
                                      sortable={false}
                                      filter={false}
                        />
                        <AgGridColumn headerName="Alias" field="nickName"/>
                        <AgGridColumn headerName="Nom" field="name"/>
                    </AgGridReact>

                </div>

                <div className={classNames("invitations", "ag-theme-alpine-dark")}>
                    <div>
                        <span className="options">Invitations</span>
                    </div>
                    <AgGridReact
                        modules={AllCommunityModules}

                        // setting default column properties
                        defaultColDef={{
                            resizable: true,
                            sortable: true,
                            filter: true,
                        }}

                        rowData={this.props.invitations??[]}>
                        <AgGridColumn headerName="Nom" field="name"/>
                        <AgGridColumn headerName="Email" field="email"/>
                        <AgGridColumn headerName="Date" field="date"/>
                    </AgGridReact>

                </div>

                <div className={"invitation-form"}>
                    <Form>
                        <Form.Row className="align-items-center">
                            <Col md="4">
                                <Form.Control className={"my-1"}
                                              placeholder={"Prénom Nom"} value={this.state.inviteeName}
                                              onChange={e => this.changeName(e.target.value)}
                                              isValid={!!this.state.inviteeEmail && !!this.state.inviteeName}
                                              isInvalid={!!this.state.inviteeEmail && !this.state.inviteeName}/>
                            </Col>
                            <Col md="5">
                                <Form.Control className={"my-1"}
                                              placeholder={"Email"} value={this.state.inviteeEmail}
                                              onChange={e => this.updateEmail(e.target.value)}
                                              isValid={!this.state.inviteeFormErrors && !!this.state.inviteeEmail}
                                              isInvalid={!!this.state.inviteeFormErrors && !!this.state.inviteeEmail}
                                />
                            </Col>
                            <Col md="3">
                                <Button className={"my-1 flex-grow-1"}
                                        disabled={!this.state.inviteeEmail || !this.state.inviteeName || !!this.state.inviteeFormErrors}
                                        onClick={this.sendInvitation.bind(this)}
                                >
                                    <FontAwesomeIcon icon={faShareAlt}/> Inviter
                                </Button>
                            </Col>
                        </Form.Row>
                    </Form>
                </div>
            </>
        );
    }

    private updateEmail(email: string) {
        this.setState({inviteeEmail: email});
        let results = validate.single(email, EMAIL_CONSTRAINTS);
        if (results) {
            let emailError = results[0];
            this.setState({inviteeFormErrors: emailError});
        } else {
            this.setState({inviteeFormErrors: undefined});
        }
    }

    private changeName(value: string) {
        this.setState({inviteeName: value});
    }

    private async sendInvitation() {

        let {inviteeName, inviteeEmail} = this.state;

        let userInvitation: UserInvitation = {
            email: inviteeEmail,
            name: inviteeName,
            type: UserInvitationTypeEnum.UserInvitation,
        };

        await this.invitationService.invite([userInvitation]);

        this.setState({inviteeName: "", inviteeEmail: ""});
    }
}

export default RoomOccupants;