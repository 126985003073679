import React, {Component} from "react";
import hgr, {ApiCall, NotificationActionType, NotificationActionTypeTypeEnum} from "hgr-api";
import {Button} from "react-bootstrap";
import {faBell} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type Props = {
    notification: hgr.Notification,
    onActionCalled?: (apiCall : ApiCall) => Promise<any>,
    onClose?: () => void,
}

class NotificationLine extends Component<Props>{

    render() {
        let notification = this.props.notification;
        return (
            <div className="userNotifyView text-light">
                {NotificationLine.getNotificationImage(notification.image)}
                <div className="userRowBox">
                    <span className="notifyTtx">{notification.summary}</span>
                    {
                        (notification.actions && notification.actions.length > 0) &&

                        <div className="notification-actions">
                            {
                                notification.actions.map(action =>
                                    <Button size={"sm"} key={notification.ref + "-" + action.type}
                                            variant={"primary"}
                                            className={NotificationLine.getClassNameForAction(action.type)}
                                            onClick={() => this.applyAction(action)}
                                    >
                                        {action.label}
                                    </Button>)
                            }
                        </div>

                    }
                </div>
            </div>
        );
    }

    private static getNotificationImage(image: string | undefined) {
        if (image) {
            return <img src={image} className="avatarLogo" alt={"logo de notification"}/>;
        } else {
            return <FontAwesomeIcon icon={faBell}/>
        }
    }

    private static getClassNameForAction(type: NotificationActionTypeTypeEnum | undefined) {
        switch (type) {
            case NotificationActionTypeTypeEnum.Cancel:
                return "reject";
            case NotificationActionTypeTypeEnum.Validate:
                return "confirmation";
            default:
                return "default-action";
        }
    }

    private async applyAction(action: NotificationActionType) {
        if (action.callRef && action.callRef.method && action.callRef.url && this.props.onActionCalled) {
            await this.props.onActionCalled(action.callRef);
        }
        if (this.props.onClose) {
            this.props.onClose();
        }
    }
}

export default NotificationLine;