import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import 'react-toastify/scss/main.scss'
import './index.scss';

import setupAxiosInterceptors from "./components/config/axios-interceptor";

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import {AuthService} from "./services/auth-service";
import {AppConfig} from "./components/config/constants";
import jquery from 'jquery';
import {LoggerFactory} from "./components/utils/ConfigLog4j";
import {createBrowserHistory} from "history";
import {UserService} from "./services/user-service";

const LOGGER = LoggerFactory.getLogger("hgr.Index");

// noinspection JSIgnoredPromiseFromCall
jquery.getJSON("conf/env.json", envConfig => {
    LOGGER.debug("Received env configuration: " + JSON.stringify(envConfig));
    AppConfig.ENVIRONMENT = envConfig;

    // checks that the user, if any, exists server side
    let userService = new UserService();
    userService.refreshLocalProfile().finally(() =>
        ReactDOM.render(
            <React.StrictMode>
                <App/>
            </React.StrictMode>,
            document.getElementById("root")
        )
    );

}).always(() => {
    LOGGER.debug("Using configuration: " + JSON.stringify(AppConfig));
    const history = createBrowserHistory();
    setupAxiosInterceptors(() => new AuthService().clearAuthenticationOnError(history));
});


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
