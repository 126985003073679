import React, {Component, createRef} from "react";
import './welcome.scss'
import CompanySearchBar from "./CompanySearchBar";
import {Button} from "react-bootstrap";
import {faArrowCircleDown} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class HgrWelcomeCover extends Component {
    private welcomeCoverRef = createRef<HTMLDivElement>();

    render() {
        return (
            <div ref={this.welcomeCoverRef} className="heading">
                <div className={"vail h-100 w-100"}/>
                <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
                    <header className={"mb-auto"}/>
                    <div role={"main"}>
                        <h1 className="display-3">Le Travail,</h1>
                        <h2 className="display-4 mb-5">Comme si j'y étais!</h2>
                        <CompanySearchBar/>
                    </div>
                    <footer className={"mt-auto justify-content-center"}>
                        <Button variant={"link"} className={"col text-center"}
                                onClick={() => this.scrollDown()}>
                            En savoir plus
                            <br/>
                            <FontAwesomeIcon icon={faArrowCircleDown}/>
                        </Button>
                    </footer>
                </div>
            </div>
        );
    }

    private scrollDown() {

        window.scrollTo({
            top: this.welcomeCoverRef.current?.clientHeight,
            behavior: "smooth"
        });
    }
}