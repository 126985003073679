import React, {Component} from "react";
import './welcome.scss'
import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import {AuthService} from "../../../services/auth-service";
import {RouteComponentProps} from "react-router-dom";
import {UserService} from "../../../services/user-service";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDoorOpen, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";

export default class HgrWelcomeHeader extends Component<RouteComponentProps> {

    private authService: AuthService = new AuthService();

    private userService: UserService = new UserService();

    render() {
        let user = this.userService.getLocalProfile();
        let userPicture = this.userService.getUserAvatar(user);

        let userProfileMenu = <span className={"colorLink"}><img src={userPicture} alt={"user"}
                                                                 className={"profile-img rounded-circle"}/> {user.name}</span>;

        return (
            <Navbar className="navbar navbar-expand-lg navbar-dark bg-dark bgColor">
                <Navbar.Brand href="/">
                    <img src="/img/logo.PNG" className="logo" alt="hologramme"/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll"/>
                <Navbar.Collapse id="navbarScroll" className="justify-content-end">
                    <Nav className={"align-items-center"}>
                        {user.company &&
                        <Nav.Link className={"text-primary"} href={"/home"}><FontAwesomeIcon icon={faDoorOpen}/> Entrer
                            dans les locaux</Nav.Link>
                        }
                        <NavDropdown title={userProfileMenu} id="user-dropdown">
                            <NavDropdown.Item className={"text-danger"} onClick={() => this.handleLogout()}>
                                <FontAwesomeIcon icon={faSignOutAlt}/> Se déconnecter
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>

            </Navbar>
        );
    }

    private handleLogout() {
        this.authService.logout().then(() => this.props.history.push({ pathname: "/see-you-soon" }));
    }
}
