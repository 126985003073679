import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Button, Col, Container, Form, InputGroup, Modal, Row} from "react-bootstrap";
import API from "../../services/api-service";
import {ManagedUserVM} from "hgr-api";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {faEyeSlash} from "@fortawesome/free-regular-svg-icons";
import {Logger} from "typescript-logging";
import {LoggerFactory} from "../utils/ConfigLog4j";
import {AppConfig} from "../config/constants";

type RegisterProps = {
    show: boolean,
    onRegister: (email: string) => void,
    onClose: () => void,
}

type RegisterState = {
    acceptedCGU: boolean,
    acceptedJitsiCGU: boolean,
    showPassword: boolean,
    login?: string,
    email?: string,
    password?: string,
    firstname?: string,
    lastname?: string,
    error?: string,
    success?: boolean,
}

class Register extends Component<RegisterProps, RegisterState> {

    private logger: Logger = LoggerFactory.getLogger("hgr.Register");

    constructor(props: RegisterProps) {
        super(props);
        this.state = {acceptedCGU: false, acceptedJitsiCGU: false, showPassword: false};
    }

    render() {
        let cguCheckLabel = <Form.Label>J'accepte les <a href={"/cgu-app-hologramme.pdf"} target={"cgu"}>conditions
            d'utilisation</a> .</Form.Label>;

        let jitsiCguCheckLabel = <Form.Label>J'accepte les <a href={"https://jitsi.org/meet-jit-si-terms-of-service/"}
                                                              target={"cgu"}>conditions d'utilisation de <b>Jitsi
            Meet</b>.</a></Form.Label>;

        return (

            <Modal show={this.props.show} onHide={this.props.onClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>Créer un compte</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Container>
                            <Row>
                                <Col>
                                    <Form.Row>
                                        <Form.Group as={Col} className="mb-2">
                                            <Form.Label>Prénom</Form.Label>
                                            <Form.Control type="firstname" placeholder="Prénom"
                                                          onChange={(e) => this.setState({firstname: e.target.value})}/>
                                        </Form.Group>

                                        <Form.Group as={Col} className="mb-2">
                                            <Form.Label>Nom</Form.Label>
                                            <Form.Control type="lastname" placeholder="Nom"
                                                          onChange={(e) => this.setState({lastname: e.target.value})}/>
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Group className="mb-2">
                                        <Form.Label>Choisir un identifiant</Form.Label>
                                        <Form.Control type="text" placeholder="Identifiant"
                                                      required
                                                      isInvalid={!this.state.login}
                                                      onChange={(e) => this.setState({login: e.target.value})}/>
                                    </Form.Group>


                                    <Form.Group className="mb-2" controlId="formBasicEmail">
                                        <Form.Label>Saisir votre adresse mail</Form.Label>
                                        <Form.Control type="email" placeholder="Adresse mail"
                                                      required
                                                      isInvalid={!this.state.email}
                                                      onChange={(e) => this.setState({email: e.target.value})}/>
                                    </Form.Group>

                                    <Form.Group className="mb-2" controlId="formBasicPassword">
                                        <Form.Label>Choisir un mot de passe</Form.Label>
                                        <InputGroup>
                                            <Form.Control type={this.state.showPassword ? "text" : "password"}
                                                          required
                                                          isInvalid={!this.state.password}
                                                          placeholder="Mot de passe"
                                                          onChange={(e) => this.setState({password: e.target.value})}/>
                                            <InputGroup.Append>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon
                                                        onClick={() => this.setState({showPassword: !this.state.showPassword})}
                                                        icon={this.state.showPassword ? faEyeSlash : faEye}/>
                                                </InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Form.Group>

                                    <Form.Group className="mt-4 mb-2" controlId="acceptCGU">
                                        <Form.Check type="checkbox"
                                                    isInvalid={!this.state.acceptedCGU}
                                                    checked={this.state.acceptedCGU}
                                                    onChange={() => this.setState({acceptedCGU: !this.state.acceptedCGU})}
                                                    label={cguCheckLabel}/>
                                    </Form.Group>
                                    <Form.Group className="mb-2" controlId="acceptJitsiCGU">
                                        <Form.Check type="checkbox"
                                                    isInvalid={!this.state.acceptedJitsiCGU}
                                                    checked={this.state.acceptedJitsiCGU}
                                                    onChange={() => this.setState({acceptedJitsiCGU: !this.state.acceptedJitsiCGU})}
                                                    label={jitsiCguCheckLabel}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.error && <div className={"text-danger"}>{this.state.error}</div>}
                        {this.state.success &&
                            <div className={"text-success"}>Veuillez vérifier votre courriel pour valider votre
                                compte.</div>}
                        {!this.state.success && <Button variant="primary"
                                                        onClick={this.handleRegister.bind(this)}
                                                        disabled={!this.formValid()}>
                            S'enregister
                        </Button>
                        }
                        {this.state.success && <Button variant="primary" onClick={() => this.props.onClose()}>
                            Fermer
                        </Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }

    private formValid() {
        return this.state.email
            && this.state.password
            && this.state.acceptedCGU
            && this.state.acceptedJitsiCGU
            && this.state.firstname
            && this.state.lastname
            && this.state.login;
    }

    private async handleRegister() {
        this.logger.debug("Handle register: " + JSON.stringify(this.state));
        this.setState({error: undefined, success: undefined});
        if (this.state.email && this.state.password) {
            let api = API.getAccountApi();
            try {
                await api.register({
                    managedUserVM: {
                        email: this.state.email,
                        firstName: this.state.firstname,
                        lastName: this.state.lastname,
                        login: this.state.login,
                        password: this.state.password,
                        langKey: "FR",
                        conditionsAccepted: AppConfig.ENVIRONMENT.tcuVersion
                    } as ManagedUserVM
                });
                this.props.onRegister(this.state.email);
                this.setState({success: true})
            } catch (e) {
                this.logger.error("Error: " + e);
                this.setState({error: "Une erreur est survenue"})
            }
        }
    }
}

export default Register;