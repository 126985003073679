import {LoggerFactory} from "components/utils/ConfigLog4j";
import {
    Company,
    CompanyResourceApi,
    CompanyRoom,
    CompanySite,
    RoomAccessResponse,
    RoomAccessResponseStatusEnum
} from "hgr-api";
import API, {InseeAPI} from "./api-service";
import {NotificationService} from "./notification-service";
import {Etablissement, UniteLegale} from "sirene-api";

export class CompanyService {

    private logger = LoggerFactory.getLogger("hgr.CompanyService");

    private notificationService: NotificationService = new NotificationService();

    /**
     * getCompanyConfiguration
     */
    public async getCompanyConfiguration(companyId: string): Promise<Company> {
        let api: CompanyResourceApi = API.getCompanyApi();
        let axiosResponse = await api.company({companyRef: companyId});
        return axiosResponse.data;
    }

    public async createCompanyRoom(company: Company): Promise<CompanyRoom> {
        // find the room to create
        let currentSite: CompanySite | undefined;
        let currentRoom: CompanyRoom | undefined;
        if (company.sites && company.sites.length > 0) {
            for (let site of company.sites) {
                let room = site.rooms?.find(r => r.ref === undefined);
                if (room) {
                    currentSite = site;
                    currentRoom = room;
                    break
                }
            }
            if (!currentRoom || !currentSite) {
                throw new Error("Pas de salle en cours de création dans cet objet");
            }
        } else {
            throw new Error("A ce stade, on devrait avoir au moins un site");
        }

        let api: CompanyResourceApi = API.getCompanyApi();

        if (company.ref) {
            if (currentSite.ref) {
                this.logger.debug(() => "Creation de la salle uniquement: " + JSON.stringify(currentRoom));
                let resp = await api.createRoom({companyRef: company.ref, companySiteId: currentSite.ref, companyRoomInput: currentRoom});
                return resp.data;
            } else {
                this.logger.debug(() => "Creation du site et de la salle uniquement: " + JSON.stringify(currentSite));
                let resp = await api.createSite({companyRef: company.ref, companySiteInput: currentSite});
                let site: CompanySite = resp.data;
                // update inside the passed company
                currentSite.ref = site.ref;
                let room = site.rooms?.find(r => r?.name === currentRoom?.name);
                if (room) {
                    return room;
                } else {
                    throw new Error("La salle devrait être retournée par le serveur: " + JSON.stringify(site));
                }

            }
        } else {
            this.logger.debug(() => "lançons la création de l'entreprise: " + JSON.stringify(company));
            let resp = await api.create({companyInput: company});
            let c: Company = resp.data;
            company.ref = c.ref;
            let room = c.sites?.flatMap(s => s.rooms).find(r => r?.name === currentRoom?.name);
            if (room) {
                return room;
            } else {
                throw new Error("La salle devrait être retournée par le serveur: " + JSON.stringify(c));
            }
        }
    }

    public async searchCompany(searchValue: string): Promise<Company[]> {
        let api: CompanyResourceApi = API.getCompanyApi();
        let companies = await api.search({ direction: "ASC", orderBy: "name", page: 0, searchCompanies: searchValue, size: 10});
        return companies.data;
    }

    public async searchCompanyBySiren(siren: string): Promise<UniteLegale|undefined> {
        let api = InseeAPI.getUniteLegaleApi();
        let respUniteLegalAxios = await api.findBySiren({siren: siren});
        return respUniteLegalAxios.data?.uniteLegale;
    }

    public async getEtablissementBySiret(siret: string): Promise<Etablissement|undefined> {
        let api = InseeAPI.getEtablissementApi();
        let resp = await  api.findBySiret( {siret: siret});
        return resp.data?.etablissement;
    }

    async enterRoom(userRoom: CompanyRoom,
                    onAccessGranted?: (answer: RoomAccessResponse) => void,
                    onAccessWaiting?: (answer: RoomAccessResponse) => void,
                    onAccessDenied?: (answer: RoomAccessResponse) => void) {
        if (userRoom.companyRef && userRoom.ref && userRoom.siteRef) {
            let api = API.getCompanyApi();
            let axiosAnswer = await api.enterRoom({companyRef: userRoom.companyRef, companyRoomRef: userRoom.ref, companySiteRef: userRoom.siteRef});
            let roomAccessResponse = axiosAnswer.data as RoomAccessResponse;
            switch (roomAccessResponse.status) {
                case RoomAccessResponseStatusEnum.Ok:
                    this.notificationService.notifyUser(roomAccessResponse.message, undefined, '/img/enter-room.png');
                    if (onAccessGranted && roomAccessResponse) onAccessGranted(roomAccessResponse);
                    break;
                case RoomAccessResponseStatusEnum.Wait:
                    this.notificationService.notifyUser(roomAccessResponse.message, "knocking-"+ userRoom.ref);
                    if (onAccessWaiting && roomAccessResponse) onAccessWaiting(roomAccessResponse);
                    break;
                case RoomAccessResponseStatusEnum.Refused:
                    this.notificationService.notifyUser(roomAccessResponse.message);
                    if (onAccessDenied && roomAccessResponse) onAccessDenied(roomAccessResponse);
                    break;
            }
        } else {
            throw new Error("The room is malformed: " + JSON.stringify(userRoom));
        }

    }

    async updateCompanyRoom(roomUpdate: CompanyRoom) {
        if (roomUpdate.companyRef && roomUpdate.ref && roomUpdate.siteRef) {
            let api = API.getCompanyApi();
            await api.modifyRoom({ companyRef: roomUpdate.companyRef, companyRoomId: roomUpdate.ref, companySiteId: roomUpdate.siteRef, companyRoom: roomUpdate});
        } else {
            throw new Error("The room is malformed: " + JSON.stringify(roomUpdate));
        }
    }
    async  getCompanyRoom(companyRef: string, companyRoomRef: string, companySiteRef: string):Promise<CompanyRoom>{
        let api = API.getCompanyApi();

        let axiosResponse = await api.getRoom({companyRef: companyRef, companyRoomRef: companyRoomRef, companySiteRef: companySiteRef});
        return axiosResponse.data;
    }

    async registerInRoom(room: CompanyRoom) {
        this.logger.info("Registering in room: " + room.name);
        let api = API.getCompanyApi();
        if (room.ref && room.companyRef && room.siteRef) {
            await api.lodgeInRoom({ companyRef: room.companyRef, companyRoomRef: room.ref, companySiteRef: room.siteRef });
        } else {
            throw new Error("La reference de la salle est vide!");
        }
    }

}