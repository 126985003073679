import * as React from 'react';
import {
    Route,
    Redirect,
    RouteProps,
} from 'react-router-dom';
import {SecurityContext} from './SecurityContext';
import {LoggerFactory} from "./ConfigLog4j";

interface PrivateRouteProps extends RouteProps {
    // tslint:disable-next-line:no-any
    component: any;
    loginMessage?: string;
}

const PrivateRoute = (props: PrivateRouteProps) => {

    const logger = LoggerFactory.getLogger("hgr.PrivateRoute");

    const {component: Component, ...rest} = props;

    return (
        <Route
            {...rest}
            render={(routeProps) =>

                (<SecurityContext.Consumer>
                    {
                        auth => {
                            if (auth && auth.isAuthenticated) {
                                logger.trace(`User is authenticated for ${routeProps.location.pathname}`);
                                return <Component {...routeProps} />
                            } else {
                                logger.info(`User is not authenticated for ${routeProps.location.pathname}`);
                                logger.debug(`login message: ${props.loginMessage}`);
                                return <Redirect to={{pathname: '/login', state: {from: routeProps.location, loginMessage: props.loginMessage}}}/>
                            }

                        }
                    }
                </SecurityContext.Consumer>)
            }
        />
    );
};

export default PrivateRoute;