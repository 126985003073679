import React, {Component, FormEvent} from 'react';
import {Link, Redirect, RouteComponentProps, withRouter} from 'react-router-dom';
import {Button, Col, Form, Row} from 'react-bootstrap';
import wrapOnBoarding from './OnBoarding';
import {LoggerFactory} from 'components/utils/ConfigLog4j';
import {Company, CompanySite} from "hgr-api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretLeft} from "@fortawesome/free-solid-svg-icons";
import {CompanyService} from "../../services/company-service";

interface State {
    company: Company,
    siret?: string,
    siteName?: string,
    siteAddress?: string,
    siteZipCode?: string,
    siteTown?: string,
    siteCountry?: string,
    nameError?: string,
}


class CompanySiteCreationContent extends Component<RouteComponentProps, State> {

    private companyService: CompanyService = new CompanyService();

    private logger = LoggerFactory.getLogger("hgr.CompanySiteCreation");

    constructor(props: RouteComponentProps) {
        super(props);

        const routeProps = this.props.location?.state as State;
        this.logger.debug(() => "State from router: " + JSON.stringify(routeProps));
        if (routeProps && routeProps.company) {
            let company = routeProps.company;
            company.sites = company.sites ?? [];
            this.state = {
                company: company,
                siteName: 'Site principal',
                siret: routeProps.siret
            };
        } else {
            this.state = {} as State;
        }
    }

    componentDidMount() {
        if (this.state.siret) {
            this.setUpDefaultValues(this.state.siret);
        }
    }

    render() {
        if (!this.state.company) {
            return <Redirect to="/"/>;
        }
        return (
            <div className="company-site-creation">

                <div className="centerAlignWork">
                    <h4 className="roomHead">Enregistrement du site</h4>
                    <img src="/img/workplace.PNG" className="company" alt="workplace"/>

                    <div className="step-box">

                        <Form className={"container"}
                              onSubmit={(e) => this.moveToRoomCreation(e)}>

                            <Form.Group as={Row}>
                                <Form.Label column md={4}>Nom du site</Form.Label>
                                <Col>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Nom du site"
                                        value={this.state.siteName}
                                        isInvalid={!this.state.siteName}
                                        onChange={(e) => this.setState({siteName: e.target.value})}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column md={4}>Adresse</Form.Label>
                                <Col>

                                    <Form.Control as="textarea"
                                                  placeholder="Adresse"
                                                  id="exampleFormControlTextarea1"
                                                  value={this.state.siteAddress}
                                                  onChange={(e) => this.setState({siteAddress: e.target.value})}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column md={4}>Code postal</Form.Label>
                                <Col md={4}>
                                    <Form.Control
                                        type="text"
                                        id="inputEmail4"
                                        placeholder="Code postal"
                                        value={this.state.siteZipCode}
                                        onChange={(e) => this.setState({siteZipCode: e.target.value})}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column md={4}>Ville</Form.Label>
                                <Col md={6}>
                                    <Form.Control
                                        type="text"
                                        placeholder="Ville"
                                        value={this.state.siteTown}
                                        onChange={(e) => this.setState({siteTown: e.target.value})}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column md={4}>Pays</Form.Label>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        placeholder="Pays"
                                        value={this.state.siteCountry}
                                        onChange={(e) => this.setState({siteCountry: e.target.value})}
                                    />
                                </Col>
                            </Form.Group>

                            <Row>
                                <Col className={"align-self-start"}>
                                    <Link to="/boarding/company"><FontAwesomeIcon icon={faCaretLeft}/> Enregistrer une
                                        entreprise</Link>
                                </Col>
                                <Col md={3}>
                                    <Button block variant={"primary"}
                                            type="submit"
                                            disabled={!this.state.siteName}
                                    >
                                        Créer une salle
                                    </Button>

                                </Col>

                            </Row>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }

    private moveToRoomCreation(e: FormEvent) {
        e.preventDefault();
        let {siteName, siteAddress, siteZipCode, siteTown, siteCountry} = this.state;

        let site = {
            name: siteName
        } as CompanySite;

        if (siteAddress && siteZipCode && siteTown && siteCountry) {
            site.address = [siteAddress, siteZipCode, siteTown, siteCountry].join(", ");
        }

        let company = this.state.company;
        if (company.sites) {
            if (!company.sites.find(s => s.name === site.name)) {
                company.sites.push(site);
            }
        } else {
            company.sites = [site];
        }
        this.props.history.push({
                pathname: "/boarding/room"
            },
            {company: company, site: site}
        );

    }

    private setUpDefaultValues(siret: string) {
        this.companyService.getEtablissementBySiret(siret).then((etablissement) => {
            let adresse = etablissement?.adresseEtablissement;
            if (adresse) {
                this.setState({
                    siret: siret,
                    siteCountry: adresse.libellePaysEtrangerEtablissement??"France",
                    siteZipCode: adresse.codePostalEtablissement,
                    siteTown: adresse.libelleCommuneEtablissement??adresse.libelleCommuneEtrangerEtablissement,
                    siteName: adresse.complementAdresseEtablissement??adresse.libelleVoieEtablissement,
                    siteAddress: `${adresse.numeroVoieEtablissement??""} ${adresse.indiceRepetitionEtablissement??""} ${adresse.typeVoieEtablissement??""} ${adresse.libelleVoieEtablissement??""}`.trim()
                });
            }
        });
    }
}

const CompanySiteCreation = wrapOnBoarding(withRouter(CompanySiteCreationContent))
export default CompanySiteCreation;
