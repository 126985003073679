import React, {Component, FormEvent} from 'react';
import {Link, RouteComponentProps, withRouter} from 'react-router-dom';
import {Button, Col, Form, Row} from "react-bootstrap";
import wrapOnBoarding from './OnBoarding';
import {Company} from "hgr-api";
import {faCaretLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {UploadImage} from "../shared/upload-image/UploadImage";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {CompanyService} from "../../services/company-service";
import {UniteLegale} from "sirene-api";

interface Props {
}

interface State {
    companyName?: string,
    companySiren?: string,
    companyLogo?: string,
    loading: boolean,
    searchResult?: UniteLegale[],
    siret?: string,
}

class CompanyCreationContent extends Component<RouteComponentProps & Props, State> {

    private companyService: CompanyService = new CompanyService();

    constructor(props: RouteComponentProps & Props) {
        super(props);
        this.state = {companyName: ""} as State;
    }


    render() {
        return (
            <div className="company-creation">

                <div className="centerAlignWork">
                    <h4 className="roomHead">Etape d'inscription 1</h4>
                    <img src="/img/company.PNG" className="company" alt="company-creation"/>

                    <div className="step-box">

                        <Form className={"container"} onSubmit={(e) => this.moveToSiteCreation(e)}>
                            <Row>
                                <Col md={3}>
                                    <UploadImage imageUrl={'/img/company-default-logo.png'}
                                                 onChange={(img) => this.setState({companyLogo: img})}
                                                 onMaxSizeReached={(s, m) => console.log(`${s} > ${m}`)}
                                    />
                                </Col>
                                <Col>

                                    <Form.Group as={Row}>
                                        <Form.Label column md={4}>Numéro Siren</Form.Label>
                                        <Col md={"8"}>
                                            <AsyncTypeahead
                                                labelKey={c => c.siren??""}
                                                filterBy={() => true}
                                                isLoading={this.state.loading}
                                                minLength={9}
                                                isInvalid={!this.state.companySiren}
                                                onChange={this.onUniteLegalSelected}
                                                flip={true}
                                                highlightOnlyResult={true}
                                                onSearch={(query) => this.searchCompanyBySiren(query)}
                                                options={this.state.searchResult??[]}
                                                placeholder="XXX XXX XXX"
                                                renderMenuItemChildren={(uniteLegal) => (
                                                    <>{uniteLegal.siren} - {this.findValidPeriode(uniteLegal)?.denominationUniteLegale}</>
                                                )}
                                            />

                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row}>
                                        <Form.Label column md={4}>Nom de l'entreprise</Form.Label>
                                        <Col md={"8"}>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Nom de l'entreprise"
                                                isInvalid={!this.state.companyName}
                                                value={this.state.companyName}
                                                onChange={(e) => this.setState({companyName: e.target.value})}
                                            />
                                        </Col>
                                    </Form.Group>

                                </Col>
                            </Row>

                            <Row>
                                <Col className={"align-self-start"}>
                                    <Link to="/welcome"><FontAwesomeIcon icon={faCaretLeft}/> Rechercher une entreprise</Link>
                                </Col>
                                <Col md={3}>
                                    <Button block
                                        type="submit"
                                        variant={"primary"}
                                        disabled={!this.state.companyName || !this.state.companySiren}
                                >
                                    Créer un site
                                </Button>
                                </Col>

                            </Row>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }

    private onUniteLegalSelected = (selected: UniteLegale[] | undefined ) =>  {
        let uniteLegale = selected![0];
        if (uniteLegale) {
            let periodeUniteLegale = this.findValidPeriode(uniteLegale);
            this.setState({siret: `${uniteLegale.siren}${periodeUniteLegale?.nicSiegeUniteLegale}`, companySiren: uniteLegale.siren, companyName: periodeUniteLegale?.denominationUniteLegale});
        }
    }

    private findValidPeriode(uniteLegale: UniteLegale) {
        return uniteLegale.periodesUniteLegale?.find(p => {
            let now = new Date();
            if (p.dateDebut && p.dateFin) {
                let dd = new Date(p.dateDebut);
                let df = new Date(p.dateFin);
                return dd <= now && now <= df;
            } else if (p.dateDebut) {
                let dd = new Date(p.dateDebut);
                return dd <= now;
            } else {
                return false;
            }
        });
    }

    private moveToSiteCreation(e: FormEvent) {
        e.preventDefault();
        this.props.history.push(
            {pathname: "/boarding/site"},
            {company: {name: this.state.companyName, siren: this.state.companySiren} as Company, siret: this.state.siret}
        );
    }

    private async searchCompanyBySiren(query: string) {
        let uniteLegal = await this.companyService.searchCompanyBySiren(query);
        this.setState({ searchResult: uniteLegal ? [uniteLegal] : undefined })  ;
    }
}

const CompanyCreation = wrapOnBoarding(withRouter(CompanyCreationContent));
export default CompanyCreation;
