import React, { Component } from "react";
import {Button} from "react-bootstrap";
import {LoggerFactory} from "../../utils/ConfigLog4j";
import {RoomInfo} from "../../model/hgr-company";
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {CompanyService} from "../../../services/company-service";

type Prop = {
    circle: string,
    square: string,
    roomInfo: RoomInfo
}

class RoomBoxInner extends Component<RouteComponentProps & Prop> {

    private logger = LoggerFactory.getLogger("hgr.RoomsBox");
    private companyService: CompanyService = new CompanyService();

    render() {
        const { circle, square } = this.props;
        let roomInfo = this.props.roomInfo;
        return (
            <div className="room-box">
                <div className="viewContent">
                    <img src={circle} className="circles" alt="logosalle"/>
                    <div className="headDiscBox">
                        <h4 className="roomname">{roomInfo.room.name}</h4>
                        <h6 className="sitename">{roomInfo.site.name}</h6>
                        <p className="address">{roomInfo.site.address}</p>
                        <p className="owner">Propriétaire: {roomInfo.room.owner?.name}</p>
                    </div>
                </div>
                <div className="viewContent1">
                    <img src={square} className="squares" alt="salle"/>
                    <div className="vhcenter">
                        <Button onClick={this.registerInRoom.bind(this)} type="button" className="btn btn-success enterButStyle">
                            S'installer
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    private registerInRoom = async () => {
        let room = this.props.roomInfo.room;
        this.logger.info("Registering in room: " + room.name);
        await this.companyService.registerInRoom(room);
        this.props.history.push("/boarding/waiting-approval");
    };
}

const RoomsBox = withRouter(RoomBoxInner) ;

export default RoomsBox;