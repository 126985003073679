import React, {Component, ReactNode} from 'react';
import wrapOnBoarding from "./OnBoarding";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {Logger} from "typescript-logging";
import {LoggerFactory} from "../utils/ConfigLog4j";
import queryString from "query-string";
import {UserContext, UserProfileCtx} from "../utils/UserContext";
import {Button, Col, Form} from "react-bootstrap";
import {
    Company,
    CompanyRoom,
    CompanySite
} from "hgr-api";
import {CompanyService} from "../../services/company-service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";

type State = {
    companyRef?: string,
    siteRef?: string,
    roomRef?: string,
    company?: Company,
    site?: CompanySite,
    room?: CompanyRoom,
    apiCallOk?: boolean,
}

class WelcomeInviteeContent extends Component<RouteComponentProps, State> {

    private logger: Logger = LoggerFactory.getLogger("hgr.WelcomeInvitee");
    private companyService: CompanyService = new CompanyService();

    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {};

        const values = queryString.parse(props.location.search);
        this.logger.debug(() => "go link request: " + JSON.stringify(values));
        if (values._a) {
            let reqBase64 = values._a as string;
            let req = atob(reqBase64);
            this.logger.debug("Decoded request from link: " + req);
            let re = req.match(/company=([\w-]+)&site=([\w-]+)&room=([\w-]+)/);
            if (re) {
                this.state = {
                    companyRef: re[1],
                    siteRef: re[2],
                    roomRef: re[3],
                };
            }
        }
    }

    render() {
        if (!this.state.companyRef || !this.state.siteRef || !this.state.roomRef) {
            this.logger.warn("No req parameter in the URL. Redirecting.");
            return <h1>LOST</h1>
            //return <Redirect to={"/lost-in-space"}/>
        } else {
            if (this.state.apiCallOk === undefined) {
                this.getCompanyRoomFromServer()
                    .then(() => this.setState({apiCallOk: true}))
                    .catch((e) => {
                        this.logger.error(() => "Error calling the API: " + JSON.stringify(e));
                        this.setState({apiCallOk: false});
                    });
                return this.processingRequest;
            } else {
                if (this.state.apiCallOk) {

                    return (
                        <UserContext.Consumer>
                            {uCtx => this.displayRoomSuggestionPage(uCtx)}
                        </UserContext.Consumer>
                    );

                } else {
                    return (
                        <UserContext.Consumer>
                            {uCtx => this.suggestAnotherRoom(uCtx)}
                        </UserContext.Consumer>
                    );
                }
            }
        }
    }

    private suggestAnotherRoom(uCtx: UserProfileCtx): React.ReactNode {
        return <>
            <h3>{uCtx.user.name}</h3>
            <p>Nous n'avons pas pu retrouver la salle suggérée par votre invitation. </p>
            <p>Aurez vous plus de chance que nous en la recherchant?</p>
            <Link to="/welcome"><FontAwesomeIcon icon={faSearch}/> Rechercher une entreprise</Link>
        </>;
    }

    private processingRequest: ReactNode = <><h1>Traitement de la demande</h1></>;

    private displayRoomSuggestionPage(uCtx: UserProfileCtx) {
        return (
            <div className="room-creation">

                <div className="centerAlignWork">
                    <h4 className="roomHead">Etape d'inscription 3</h4>
                    <img src="/img/room.PNG" className="company" alt="room"/>
                    <div className="createRoomBox3">
                        <div className="header-buttons">
                            <Link to="/welcome"><FontAwesomeIcon icon={faSearch}/> Rechercher une autre salle</Link>
                        </div>
                        <Form.Group className="room3Row">
                            <Col md={4}>
                                <Form.Label className="boldText">Entreprise proposée</Form.Label>

                            </Col>
                            <Col md={8}>
                                <Form.Control plaintext readOnly
                                              defaultValue={this.state.company?.name}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group className="room3Row">
                            <Col md={4}>
                                <Form.Label className="boldText">Site proposée</Form.Label>

                            </Col>
                            <Col md={8}>
                                <Form.Control plaintext readOnly
                                              defaultValue={this.state.site?.name}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group className="room3Row">
                            <Col md={4}>
                                <Form.Label className="boldText">Salle proposée</Form.Label>
                            </Col>
                            <Col md={8}>
                                <Form.Control plaintext readOnly
                                              defaultValue={this.state.room?.name}
                                />
                            </Col>
                        </Form.Group>

                    </div>
                    <div className="create3RoomFoot">
                        <Button
                            type="button"
                            className="btn btn-success butStyle"
                            onClick={() => this.registerInRoom()}
                        >
                            S'installer dans cette salle
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    private async getCompanyRoomFromServer(): Promise<void> {
        if (this.state.companyRef && this.state.siteRef && this.state.roomRef) {
            let company = await this.companyService.getCompanyConfiguration(this.state.companyRef);
            let site = company.sites?.find(s => s.ref === this.state.siteRef);
            let room = site?.rooms?.find(r => r.ref === this.state.roomRef);
            let state = {
                company: company,
                site: site,
                room: room,
            };
            this.logger.debug(() => "Found company and room: " + JSON.stringify(state));
            if (!company || !site || !room) {
                throw new Error("Salle non trouvée");
            }
            this.setState(state)
        }
    }

    private registerInRoom = async () => {
        let room = this.state.room;
        if (room) {
            try {
                await this.companyService.registerInRoom(room);
                this.props.history.push("/boarding/waiting-approval");
            } catch (e: any) {
                this.logger.error(() => JSON.stringify(e));
                this.setState({apiCallOk: false});
            }
        } else {
            this.setState({apiCallOk: false});
        }
    };
}

const WelcomeInvitee = wrapOnBoarding(withRouter(WelcomeInviteeContent))
export default WelcomeInvitee;
