import "./waiting-approval.scss"

import React, { Component } from "react";
import {RouteChildrenProps} from "react-router-dom";
import wrapOnBoarding from "../OnBoarding";

class WaitingApprovalInner extends Component<RouteChildrenProps> {
  render() {
    return (
      <div className="waiting-approval">

        <div className="centerAlignWork">
          <h4 className="roomHeadDone">En cours d'installation</h4>

          <div className="createRoomBox4">
            <img src="/img/tick.PNG" className="tickLogo" alt="ok"/>

            <h5 className="greenBoldTxt">La demande d'installation a été envoyée.</h5>
            <p>
              Nous attendons validation du propriétaire de la salle. Vous recevrez un mail de confirmation après
              sa validation.
            </p>

            <div className="create4RoomFoot">
              <button
                type="button"
                className="btn btn-success butStyle"
                onClick={() => this.props.history.push("/see-you-soon")}
              >
                Retourner à l'acceuil
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const WaitingApproval = wrapOnBoarding(WaitingApprovalInner);
export default WaitingApproval;
