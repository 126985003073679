import React, {Component} from 'react';
import "./notification.scss"
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell} from "@fortawesome/free-solid-svg-icons";
import {Flip, ToastContainer} from "react-toastify";
import {NotificationService} from "../../../services/notification-service";
import {Badge} from "react-bootstrap";

type Props = {

}

type State = {
    opened: boolean,
    nbNotifications: number,
}

const NOTIFICATIONS_CONTAINER_ID = "hgr-notifications-pane";
export default class NotificationPane extends Component<Props, State> {

    private notificationService: NotificationService = new NotificationService();

    constructor(props:  Props) {
        super(props);
        this.state = {
            opened: false,
            nbNotifications: 0
        };
    }

    componentDidMount() {
        this.notificationService.onNotificationCallback((nb, containerId) => {
            if (containerId === NOTIFICATIONS_CONTAINER_ID) {
                if (nb > 0) {
                    this.setState({opened: true, nbNotifications: nb});
                } else {
                    this.setState({opened: false, nbNotifications: 0});
                }
            }
        });
    }

    render() {
        let className = classNames({"bg-dark": true, "notification-panel" : true, "opened": this.state.opened});
        return (
            <div className={className}>
                <div className="notification-panel-header text-light" onClick={this.openPanel.bind(this)}>
                    <span className="notifyPanelText">
                        Notifications
                        {this.state.nbNotifications > 0 && <Badge variant={"primary"}>{this.state.nbNotifications}</Badge> }
                    </span>
                    <FontAwesomeIcon icon={faBell}
                                     className={classNames({"hgr-icon": true, "active": this.state.nbNotifications > 0})} />
                </div>
                <div className={classNames({"notifications": true, "hidden": this.state.nbNotifications === 0})}>
                    <ToastContainer containerId={NOTIFICATIONS_CONTAINER_ID}
                                    enableMultiContainer={true}
                                    transition={Flip}
                                    icon={false}
                    />
                </div>
            </div>
        );
    }

    private openPanel() {
        this.setState({
            opened: !this.state.opened
        });
    }
}

