import React, {Component} from 'react';
import "./index.scss"
import {createBrowserHistory} from "history";

class HgrAccessDenied extends Component {

    componentDidMount() {
        setTimeout(() => {
            const history = createBrowserHistory();
            history.push('/')
        }, 2000)
    }

    render() {
        return (
            <div className="access-denied">
                <div className="veil">
                    <h1>Accès refusé!</h1>
                    <h1>
                        <a href={"/"}>
                            Retourner à l'accueil
                        </a>
                    </h1>
                </div>
            </div>
        );
    }
}

export default HgrAccessDenied;