import queryString from 'query-string';
import React, {Component} from 'react';
import {Redirect, RouteComponentProps} from "react-router";
import {LoggerFactory} from "../utils/ConfigLog4j";
import {Logger} from "typescript-logging";
import API from "../../services/api-service";
import {ApiCallMethodEnum, NotificationActionTypeTypeEnum} from "hgr-api";

type State = {
    req?: string,
    action?: NotificationActionTypeTypeEnum,
    apiCallOk?: boolean
}

class OfficeAssignmentRequest extends Component<RouteComponentProps, State> {
    private logger: Logger = LoggerFactory.getLogger("hgr.OfficeAssignmentRequest");

    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {};

        const values = queryString.parse(props.location.search);
        this.logger.debug(() => "go link request: " + JSON.stringify(values));
        if (values._a) {
            let reqBase64 = values._a as string;
            let req = Buffer.from(reqBase64, "base64").toString();
            this.logger.debug("Decoded request from link: " + req);
            let re = req.match(/[^?]+\?answer=(VALIDATE|CANCEL)/);
            if (re) {
                let action  = re[1];
                this.state = {
                    req: req,
                    action: action as NotificationActionTypeTypeEnum
                };
            }
        }
    }

    render() {
        if (!this.state.req || !this.state.action) {
            this.logger.warn("No req parameter in the URL. Redirecting.");
            return <Redirect to={"/lost-in-space"}/>
        } else {
            if (this.state.apiCallOk === undefined) {
                API.callApi(ApiCallMethodEnum.Get, this.state.req)
                    .then(() => this.setState({apiCallOk: true}))
                    .catch((e) => {
                        this.logger.error(() => "Error calling the API: " + JSON.stringify(e));
                        this.setState({apiCallOk: false});
                    });
                return this.processingRequest;
            } else {
                if (this.state.apiCallOk) {
                    switch (this.state.action) {
                        case NotificationActionTypeTypeEnum.Validate:
                            return this.assignmentRequestAccepted;
                        case NotificationActionTypeTypeEnum.Cancel:
                            return this.assignmentRequestRefused;
                        default:
                            return this.invalidLink;
                    }
                } else {
                    return this.invalidLink;
                }
            }

        }
    }

    private processingRequest: JSX.Element = (<>
        <h1>Traitement de la demande en cours ...</h1>
    </>);

    private assignmentRequestAccepted: JSX.Element = (<>
        <h1 className={"text-success"}>Vous avez accepté la requête d'installation</h1>
    </>);

    private assignmentRequestRefused: JSX.Element = (<>
        <h1 className={"text-info"}>Vous avez refusé la requête d'installation</h1>
    </>);

    private invalidLink: JSX.Element = (<h1 className={"text-warning"}>Lien invalide</h1>);
}

export default OfficeAssignmentRequest;
