import React, {createRef} from "react";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan, faCamera} from "@fortawesome/free-solid-svg-icons";
import {LoggerFactory} from "../../utils/ConfigLog4j";
import './UploadImage.scss';
import {AppConfig} from "../../config/constants";

type Props = {
    imageUrl?: string,
    onChange?: (newImageAsB64DataUrl: string) => void,
    onMaxSizeReached?: (imgSizeInKB: number, maxSizeInKB: number) => void,
}

type State = {
    newImageUrl?: string,
}

export class UploadImage extends React.Component<Props, State> {

    private logger = LoggerFactory.getLogger("hgr.UploadImage");

    private hiddenFileInputRef = createRef<HTMLInputElement>();

    constructor(props: Props) {
        super(props);
        this.state = {}
    }

    render() {
        return <div className="image-upload">
            <div className="image-preview">
                <div className="image-edit">
                    <form action="" method="post" id="form-image">
                        <input ref={this.hiddenFileInputRef}
                               onChange={(e) => this.handleImageSelected(e)}
                               type="file" id="imageUpload" accept=".png, .jpg, .jpeg"/>
                        {this.state.newImageUrl &&
                        <Button variant={"light"} className={"btn-icon"} onClick={() => this.revertImage()}>
                            <FontAwesomeIcon icon={faBan}/>
                        </Button>
                        }
                        <Button className={"btn-icon"} onClick={() => this.selectImage()}>
                            <FontAwesomeIcon icon={faCamera}/>
                        </Button>
                    </form>
                </div>
                <div className="circles" style={{backgroundImage: `url(${this.state.newImageUrl ?? this.getOriginalImageUrl()})`}}/>
            </div>
        </div>;
    }

    private getOriginalImageUrl() {
        if (this.props.imageUrl) {
            return this.props.imageUrl;
        } else {
            return "/img/no-image-icon.png";
        }
    }

    private selectImage() {
        this.hiddenFileInputRef.current?.click();
    }

    private revertImage() {
        this.setState({newImageUrl: undefined});
    }

    private handleImageSelected(e: React.ChangeEvent<HTMLInputElement>) {
        const fileUploaded: File | undefined = e.target.files?.[0];
        if (fileUploaded) {
            this.logger.debug(`Uploaded: ${fileUploaded.name} - ${fileUploaded.size}`);
            if (fileUploaded.size > AppConfig.ENVIRONMENT.pictureMaxSizeInKb * 1024) {
                this.props.onMaxSizeReached?.(Math.floor(fileUploaded.size / 1024), AppConfig.ENVIRONMENT.pictureMaxSizeInKb);
            } else {
                let reader = new FileReader();
                reader.readAsDataURL(fileUploaded);
                reader.onload = (evt) => {
                    let picture = evt.target?.result;
                    if (picture && typeof (picture) === "string") {
                        this.setState({newImageUrl: picture});
                        this.props.onChange?.(picture);
                    }
                }
            }
        }
    }
}