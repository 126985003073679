import React, {Component} from 'react';

class LostInSpace extends Component {
    render() {
        return (
            <div>
                <h1>Perdu dans l'espace !</h1>
            </div>
        );
    }
}

export default LostInSpace;