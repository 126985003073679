import React, { useContext } from 'react';
import { SocialLoginAccessToken } from 'components/model/hgr-user';

export type Authentication = {
  isAuthenticated: boolean,
  principal: { name: string },
  credentials: {
    jwt: string,
    loginProviderToken : { provider: string, token: SocialLoginAccessToken}
  },
}

export const SecurityContext = React.createContext<Authentication>({isAuthenticated: true} as Authentication);

export function useSecurityContext() {
  return useContext(SecurityContext);
}