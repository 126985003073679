import "./confirmation.scss"

import React, { Component } from "react";
import {RouteChildrenProps} from "react-router-dom";
import wrapOnBoarding from "../OnBoarding";

class ConfirmationInner extends Component<RouteChildrenProps> {
  render() {
    return (
      <div className="invitations-confirmation">

        <div className="centerAlignWork">
          <h4 className="roomHeadDone">Fait!</h4>
          <img src="/img/done.PNG" className="company"  alt="evolution-100"/>

          <div className="createRoomBox4">
            <img src="/img/tick.PNG" className="tickLogo" alt="Fait"/>

            <h5 className="greenBoldTxt">Les invitations ont été envoyées!</h5>

            <div className="circleOuter">
              <div className="circleInner">:04</div>
            </div>

            <div className="create4RoomFoot">
              <button
                type="button"
                className="btn btn-success butStyle"
                onClick={() => this.props.history.push("/home")}
              >
                Entrer dans la salle
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const Confirmation = wrapOnBoarding(ConfirmationInner);
export default Confirmation;