import {LoggerFactory} from "../components/utils/ConfigLog4j";
import {toast, ToastOptions} from "react-toastify";
import {ApiCall, Notification} from "hgr-api";
import React from "react";
import NotificationLine from "../components/home/notification/NotificationLine";
import API from "./api-service";
import {OnChangeCallback} from "react-toastify/dist/core";

export type NotifyCallback = OnChangeCallback;

export class NotificationService {

    private logger = LoggerFactory.getLogger("hgr.NotificationService");

    private toastOptions : ToastOptions = {
        containerId: "hgr-notifications-pane",
        closeOnClick: false,
        draggable: false,
        pauseOnFocusLoss: false,
        icon: false
    };

    notifyUser(message: string | undefined, toastId?: string, image?: string) {
        this.logger.info("Received message: " + message);
        if (message) {

            let notification = {summary: message, ref: toastId, image: image};
            toast.info(
                <NotificationLine notification={notification}/>,
                {
                    ...this.toastOptions,
                    toastId: notification.ref,
                });
        }
    }

    askForAction(notification: Notification, afterActionCalled?: () => void) {
        this.logger.info(() => "Asking action from user: " + JSON.stringify(notification));
        if (notification) {
            let isActionRequired = notification.actions ? notification.delay : false;

            toast.info(
                <NotificationLine notification={notification}
                                  onClose={() => toast.dismiss(notification.ref)}
                                  onActionCalled={this.onActionCalled(afterActionCalled)}/>,
                {
                    ...this.toastOptions,
                    toastId: notification.ref,
                    autoClose: isActionRequired,
                });
        }
    }

    private onActionCalled(afterActionCalled: (() => void) | undefined) {
        return (apiCall: ApiCall) => API.callApi(apiCall.method, apiCall.url, apiCall.data)
            .then(afterActionCalled);
    }

    notifyForAPIError(detail: string | undefined) {
        this.logger.error("Received API error message: " + detail);
        if (detail) {
            toast.error(detail, {
                containerId: "hgr-default-notifications",
            });
        }
    }

    onNotificationCallback(onNotify : NotifyCallback) {
        toast.onChange(onNotify);
    }
}