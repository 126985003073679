import {SocialUserProfile} from "../components/model/hgr-user";
import {AuthService} from "./auth-service";
import {User, UserIdentityProviderEnum, UserResourceApi} from "hgr-api";
import {StorageService} from "./storage-service";
import {STORAGE_HGR_USER_KEY} from "../components/config/constants";
import API from "./api-service";
import {Logger} from "typescript-logging";
import {LoggerFactory} from "../components/utils/ConfigLog4j";
import  CryptoJS from "crypto-js";

export class UserService {
    
    private authService: AuthService = new AuthService();
    private logger: Logger = LoggerFactory.getLogger("hgr.UserService");

    /**
     * getProfile
     */
    public getLocalProfile() : User {
        let hgrUserStr = StorageService.local.get(STORAGE_HGR_USER_KEY);
        if (hgrUserStr) {
            return JSON.parse(hgrUserStr) as User;
        } else {
            throw new Error("L'utilisateur devrait être dans le stockage local");
        }
    }

    public async refreshLocalProfile() {
        let hgrUserStr = StorageService.local.get(STORAGE_HGR_USER_KEY);
        if (hgrUserStr) {
            let user = JSON.parse(hgrUserStr) as User;
            if (!user.ref) {
                await this.authService.logout();
                return;
            }
            let api = API.getUserApi();
            try {
                let axiosResponse = await api.getUser({ ref: user.ref });
                StorageService.local.set(STORAGE_HGR_USER_KEY, JSON.stringify(axiosResponse.data));
            } catch (e: any) {
                this.logger.error(() => "Utilisateur introuvable: " + JSON.stringify(e))
                await this.authService.logout();
                return;
            }
        }
    }

    public async updateUser(userProfile: User): Promise<void> {
        if (userProfile.ref) {
            let userApi = API.getUserApi();
            await userApi.modifyUser({ ref: userProfile.ref, user: userProfile });
            StorageService.local.set(STORAGE_HGR_USER_KEY, JSON.stringify(userProfile));
        } else {
            throw new Error("L'utilisateur devrait avoir une reference à cet étape");
        }
    }

    /* LOGIN */
    public async handleLoginWithSocial(user: SocialUserProfile) : Promise<User> {
        console.log(user);
        let hgrProvider = UserIdentityProviderEnum.Google;
        switch (user.provider) {
            case "google":
                hgrProvider = UserIdentityProviderEnum.Google;
                break;
            case "facebook":
                hgrProvider = UserIdentityProviderEnum.Facebook;
                break;
            case "linkedin":
                hgrProvider = UserIdentityProviderEnum.Linkedin;
                break;
        }
        return await this.authService.authenticateWithProviderToken(hgrProvider, user.token);
    }
    
    public async handleLoginWithHGR(login: string | undefined, password: string | undefined, rememberMe: boolean | undefined): Promise<User> {
        if (login && password) {
            return await this.authService.authenticateWithLogin(login, password, rememberMe ?? false);
        }
        throw new Error("The login or password can't be null");
    }

    public handleLoginFailure(error: any) {
        console.log(error);
    }

    public async logout() {
        let userApi = API.getUserApi();
        await userApi.logout();
        await this.authService.logout();
    }

    public async getUserByRef(ref: string): Promise<User> {
        let api: UserResourceApi = API.getUserApi();
        let axiosResponse = await api.getUser({ ref: ref });
        return axiosResponse.data;
    }

    public getUserAvatar(user: User) : string | undefined {

        if (user.picture) {
            return user.picture;
        } else {
            // Try to get from gravatar
            if (user.email) {
                return "https://www.gravatar.com/avatar/" + CryptoJS.MD5(user.email.toLowerCase()).toString(CryptoJS.enc.Hex) + "?d=mp";
            } else {
                return undefined;
            }
        }

    }

}