import React from "react";

import Steps from "../steps/Steps";
import HgrWelcomeCover from "./HgrWelcomeCover";
import HgrWelcomeHeader from "./HgrWelcomeHeader";
import {RouteComponentProps} from "react-router-dom";


type State = {}

class HgrWelcome extends React.Component<RouteComponentProps, State> {

    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {} as State;
    }

    render() {
        return (
            <div className="welcome">
                <HgrWelcomeHeader {...this.props as RouteComponentProps}/>
                <HgrWelcomeCover />
                <Steps/>
            </div>
        );
    }

}

export default HgrWelcome;

