
import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';

import {AppConfig, STORAGE_AUTH_TOKEN_KEY} from 'components/config/constants';
import {StorageService} from "../../services/storage-service";
import {NotificationService} from "../../services/notification-service";

const TIMEOUT = 60 * 1000;
axios.defaults.timeout = TIMEOUT;

const setupAxiosInterceptors = (onUnauthenticated: () => void) => {
  const onRequestSuccess  = (config: AxiosRequestConfig) => {
    if (config.url?.startsWith(AppConfig.ENVIRONMENT.apiUrl)) {
      const token = StorageService.local.get(STORAGE_AUTH_TOKEN_KEY) || StorageService.session.get(STORAGE_AUTH_TOKEN_KEY);
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    } else if (config.url?.startsWith(AppConfig.ENVIRONMENT.inseeApiUrl)) {
      const token = AppConfig.ENVIRONMENT.inseeToken;
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  };
  const onResponseSuccess = (response: AxiosResponse) => response;
  const onResponseError = (err: { status: any; response: { status: any; data: { message: string}}; }) => {
    const status = err.status || (err.response ? err.response.status : 0);
    switch (status) {
      case 401:
      case 403:
        onUnauthenticated();
        break;
      case 500:
        new NotificationService().notifyForAPIError(err.response.data.message);
        break;
    }
    return Promise.reject(err);
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
