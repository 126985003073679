import React, {Component} from 'react';

import './Onboarding.scss';
import {Redirect, RouteChildrenProps} from 'react-router-dom';
import wrapOnBoarding from './OnBoarding';
import {CompanyService} from 'services/company-service';
import {InvitationService} from 'services/invitation-service';
import {LoggerFactory} from 'components/utils/ConfigLog4j';
import {Company, CompanyRoom, UserInvitation, UserInvitationTypeEnum} from "hgr-api";
import {InvitationLine} from "./InvitationLine";
import {Button, Form} from "react-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDoorOpen} from "@fortawesome/free-solid-svg-icons";

export type Invitee = {
    ref: string;
    name?: string,
    email?: string,
}

type State = {
    company: Company,
    room: CompanyRoom,
    invitees: Invitee[],
    maxRef: number,
    formValid: boolean,
}

class ColleaguesInvitationContent extends Component<RouteChildrenProps, State> {
    private logger = LoggerFactory.getLogger("hgr.ColleaguesInvitationContent");

    invitationService = new InvitationService();
    companyService = new CompanyService();

    constructor(props: RouteChildrenProps) {
        super(props);
        const routeProps = this.props.location?.state as State;
        this.logger.debug(() => "State from router: " + JSON.stringify(routeProps));
        if (routeProps) {
            this.state = {
                company: routeProps.company,
                room: routeProps.room,
                invitees: [{ref: "1"} as Invitee, {ref: "2"} as Invitee, {ref: "3"}],
                maxRef: 4,
                formValid: false,
            } as State;
        } else {
            this.state = {} as State;
        }

        this.changeInvitee.bind(this)

    }

    render() {
        if (!this.state.company && !this.state.room) {
            return <Redirect to="/"/>;
        }
        return (
            <div className='invitations'>

                <div className="centerAlignWork">
                    <h4 className="roomHead">Etape d'inscription 4</h4>
                    <img src="/img/invite.PNG" className="company" alt="invitation step"/>

                    <Form className="step-box container">

                        {this.state.invitees.map(invitee => (
                            <InvitationLine key={invitee.ref} invitee={invitee}
                                            onChange={i => this.changeInvitee(invitee.ref, i)}/>
                        ))}

                        <div className="lastColumn">
                            <Button className="addInput" onClick={() => this.setState({
                                invitees: [...this.state.invitees, {ref: `${this.state.maxRef}`}],
                                maxRef: this.state.maxRef + 1
                            })}>
                                <span className="plusIcon">+</span>
                            </Button>
                        </div>

                        <div className="create4RoomFoot">
                            <Button variant={"link"}
                                    onClick={() => this.props.history.push("/home")}
                            >
                                <FontAwesomeIcon icon={faDoorOpen}/> Entrer directement
                            </Button>
                            <Button variant={"primary"}
                                type="button"
                                disabled={!this.state.formValid}
                                onClick={() => this.invite()}
                            >
                                Inviter
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }

    private changeInvitee = (ref: string, invitee: Invitee | undefined) => {
        let invitees = this.state.invitees;
        invitees.forEach(i => {
            if (i.ref === ref) {
                i.name = invitee?.name;
                i.email = invitee?.email;
            }
        });
        let hasValideInvitee = invitees.some(i => !!i.email);
        this.setState({invitees: invitees, formValid: hasValideInvitee});
    }

    invite() {
        console.log("Invite: " + this.state.invitees);
        let userInvitations = this.state.invitees
            .filter(i => i.email && i.name)
            .map(i => {
                let userInvitation: UserInvitation = {
                    email: i.email,
                    name: i.name,
                    type: UserInvitationTypeEnum.UserInvitation
                };
                return userInvitation;
            });
        this.invitationService.invite(userInvitations);
        this.props.history.push("/boarding/confirm");
    }
}

const ColleaguesInvitation = wrapOnBoarding(ColleaguesInvitationContent);
export default ColleaguesInvitation;
