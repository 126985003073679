import React, {Component} from 'react';
import CompanyInfoPane from './CompanyInfoPane';

import './navigation.scss';

import {Company, CompanyRoom, RoomConfigTypeEnum, User} from "hgr-api";
import {RoomInfo} from "../../model/hgr-company";
import CompanySitePane from "./CompanySitePane";
import classNames from "classnames";
import {LoggerFactory} from "../../utils/ConfigLog4j";
import {Logger} from "typescript-logging";
import {Button, Modal, OverlayTrigger} from "react-bootstrap";
import {UserService} from "../../../services/user-service";
import ConfigureRoom from "../room-settings/ConfigureRoom";
import ConfigureUserProfile from "../user-profile/ConfigureUserProfile";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBuilding, faShareAlt, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {ExternalInvitation} from "./ExternalInvitation";

type NavigationProps = {
    company: Company,
    currentRoom: CompanyRoom,
    user: User,
    currentVRoomLink?: string,
    onRoomActivate?: (room: CompanyRoom, conferenceToken: string | undefined) => void,
    onLogout : () => void,
    updateStructure?: boolean,
    updateStructureDone: () => void,
}

type NavigationState = {
    companyRef?: string,
    contentClasses: string,
    activeSiteIndex: number,
    activeRoomGroupIndex: number,
    activeRoomGroupType: RoomConfigTypeEnum,
    hasStructureUpdate: boolean,
    roomsMap: Map<string, RoomInfo>,
    showLogoutModal: boolean,
    opened: boolean,
}

class NavigationPane extends Component<NavigationProps, NavigationState> {
    private static logger: Logger = LoggerFactory.getLogger("hgr.NavigationPane");

    private userService: UserService = new UserService();

    constructor(props: NavigationProps) {
        super(props);
        this.state = {
            companyRef: this.props.company.ref,
            contentClasses: "navigation-pane",
            activeSiteIndex: 0,
            activeRoomGroupType: RoomConfigTypeEnum.OpenSpace,
            hasStructureUpdate: false,
            activeRoomGroupIndex: 0,
            roomsMap: new Map(),
            showLogoutModal: false,
            opened: true,
        };
    }

    componentDidMount() {
        let roomsMap = this.convertToRoomToSiteTreeMap(this.props.company);
        this.setState({
            roomsMap: roomsMap,
        });
    }

    componentDidUpdate(prevProps: Readonly<NavigationProps>, prevState: Readonly<NavigationState>, snapshot?: any) {
        if (prevState.roomsMap && prevState.companyRef
            && this.props.company.ref !== prevState.companyRef) {
            NavigationPane.logger.info(() => "Nous changeons d'entreprise: " + JSON.stringify(this.props.company));
            let roomsMap = this.convertToRoomToSiteTreeMap(this.props.company);
            this.setState({
                roomsMap: roomsMap
            });
        }
        if (this.props.updateStructure) {
            let roomsMap = this.convertToRoomToSiteTreeMap(this.props.company);
            this.props.updateStructureDone();
            return {
                roomsMap: roomsMap
            }
        }
    }

    render() {
        let currentRoom = this.props.currentRoom;
        return (
            <div className={classNames({"opened": this.state.opened, "navigation-pane": true})}>
                <div className={classNames("home-button", "hgr-icon-circle")}
                     onClick={() => this.setState({opened: !this.state.opened})}>
                    <FontAwesomeIcon icon={faBuilding} className={"icon"}/>
                </div>

                <div className={"navigation-zone bg-dark"} >

                    <div className="navigation-pane-content">
                        <CompanyInfoPane company={this.props.company}/>

                        <div className="rooms-container">
                            {this.state.roomsMap.size > 0 && this.renderSites()}
                        </div>
                        <hr/>
                        <div className={"company-actions"}>
                            <div className={"left-buttons"}>
                                <Button className={"btn-icon"} variant={"light"}
                                        onClick={() => this.handleLogoutPopup()}>
                                    <FontAwesomeIcon icon={faSignOutAlt}/>
                                </Button>
                            </div>
                            <div className={"right-buttons"}>
                                {
                                    this.props.currentRoom.name && this.props.currentVRoomLink &&
                                    <OverlayTrigger
                                        trigger={"click"}
                                        placement={"top"}
                                        defaultShow={false}
                                        overlay={<ExternalInvitation vroomName={this.props.currentRoom.name}
                                                                     vroomLink={this.props.currentVRoomLink}
                                                                     vroomPassword={this.props.currentRoom.config?.password}/>}>
                                        <Button className={"btn-icon"} variant={"light"}>
                                            <FontAwesomeIcon icon={faShareAlt}/>
                                        </Button>
                                    </OverlayTrigger>
                                }
                                <ConfigureUserProfile user={this.props.user} company={this.props.company}/>
                                {
                                    currentRoom && currentRoom.owner?.ref === this.props.user.ref &&
                                    <ConfigureRoom room={currentRoom}/>
                                }
                            </div>

                        </div>
                    </div>

                </div>
                {this.renderLogoutModal()}
            </div>
        );
    }

    private renderLogoutModal() {
        return <Modal className={"dark"} show={this.state.showLogoutModal} onHide={() => this.handleCloseLogoutConfirmation()}>
            <Modal.Header>
                <Modal.Title>Nous laisser ?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Vous êtes sur le point de vous absenter de l'entreprise.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"link"} onClick={() => this.handleCloseLogoutConfirmation()}>
                    Je reste
                </Button>
                <Button variant={"primary"} onClick={this.handleLogout.bind(this)}>
                    Je pars
                </Button>
            </Modal.Footer>
        </Modal>;
    }

    private renderSites(): React.ReactNode {
        let sites = this.props.company?.sites;
        if (sites) {
            return sites.map(site => (
                <CompanySitePane key={site.ref}
                                 site={site}
                                 user={this.props.user}
                                 currentPosition={this.getCurrentPosition()}
                                 onRoomActivate={(room, conferenceToken) => this.moveToRoom(room, conferenceToken)}
                />)
            );
        }

    }

    private handleLogoutPopup() {
        this.setState({showLogoutModal: true});
    }

    private handleCloseLogoutConfirmation() {
        this.setState({showLogoutModal: false});
    }

    private async handleLogout() {
        await this.userService.logout();
        this.setState({showLogoutModal: true});
        this.props.onLogout();
    }


    private moveToRoom(room: CompanyRoom, conferenceToken: string | undefined) {
        if (room && room.ref && this.state.roomsMap) {
            this.props.onRoomActivate && this.props.onRoomActivate(room, conferenceToken);
        } else {
            NavigationPane.logger.error("The room passed is invalid: " + JSON.stringify(room));
        }
    }

    private getCurrentPosition(): RoomInfo {
        let roomInfo;
        let currentRoomRef = this.props.currentRoom.ref;
        if (this.state.roomsMap && currentRoomRef) {
            roomInfo = this.state.roomsMap.get(currentRoomRef);
        }
        if (!roomInfo) {
            throw new Error("The company information has not been processed (RoomInfoMap)");
        }
        return roomInfo;
    }

    private convertToRoomToSiteTreeMap = (company: Company) =>   {
        let roomsInfoMap = new Map<string, RoomInfo>();
        company.sites?.forEach(s => {
            s.rooms?.forEach(r => {
                if (r.ref) {
                    let info = {
                        room: r,
                        roomType: r.config?.type,
                        site: s
                    } as RoomInfo;
                    roomsInfoMap.set(r.ref, info);
                }
            });
        });
        return roomsInfoMap;
    }
}

export default NavigationPane;