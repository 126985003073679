import React, {ChangeEvent, Component, FormEvent} from 'react';
import {Link, Redirect, RouteComponentProps} from 'react-router-dom';
import wrapOnBoarding from './OnBoarding';
import {Company, CompanyRoom, CompanySite, RoomConfig, RoomConfigTypeEnum, User} from "hgr-api";
import {UserContext} from 'components/utils/UserContext';
import {LoggerFactory} from 'components/utils/ConfigLog4j';
import {Authentication} from "../utils/SecurityContext";
import {CompanyService} from "../../services/company-service";
import {UserService} from "../../services/user-service";
import {toast} from "react-toastify";
import {Alert, Button, Col, Form, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretLeft} from "@fortawesome/free-solid-svg-icons";

interface State {
    company: Company,
    createdRoom: CompanyRoom,
    site?: CompanySite,
    files?: File[],
    error?: boolean,
}

type RoomConfigInfoMap = Map<string, RoomConfig>

class RoomCreationContent extends Component<RouteComponentProps, State> {

    private logger = LoggerFactory.getLogger("hgr.RoomCreation");

    private userService = new UserService();
    private companyService = new CompanyService();

    private roomConfigMap: RoomConfigInfoMap = new Map<string, RoomConfig>();

    constructor(props: RouteComponentProps) {
        super(props);
        // FIXME
        this.roomConfigMap.set(RoomConfigTypeEnum.OpenSpace, {type: RoomConfigTypeEnum.OpenSpace});
        this.roomConfigMap.set(RoomConfigTypeEnum.Office, {type: RoomConfigTypeEnum.Office});

        const routeProps = this.props.location?.state as State;
        this.logger.debug(() => "State from router: " + JSON.stringify(routeProps));
        if (routeProps) {
            this.state = {
                company: routeProps.company,
                site: routeProps.site,
                createdRoom: {name: '', config: this.roomConfigMap.get(RoomConfigTypeEnum.OpenSpace)},
            } as State;
        } else {
            this.state = {
                createdRoom: {name: ''},
            } as State;
        }
    }

    handleChange = (files: File[]) => this.setState({files});

    render() {
        if (!this.state.company) {
            return <Redirect to="/"/>;
        }
        return (
            <UserContext.Consumer>
                {uCtx => {
                    return (
                        <div className="room-creation">

                            <div className="centerAlignWork">
                                <h4 className="roomHead">Etape d'inscription 3</h4>
                                <img src="/img/room.PNG" className="company" alt="room"/>

                                <div className="step-box">

                                    {this.state.error &&
                                    <Alert variant={"danger"}>Une erreur est survenue lors de la création de votre
                                        vidéosalle.</Alert>
                                    }
                                    <Form className={"container"}
                                          onSubmit={(e) => this.registerUserAndMoveToNextStep(e, uCtx.setAppState)}>

                                        <Form.Group as={Row}>
                                            <Form.Label column md={4}>Sélectionnez votre
                                                site</Form.Label>
                                            <Col md={8}>
                                                <Form.Control as="select" placeholder='Type de salle'
                                                              onChange={this.handleSelectWorkplace.bind(this)}
                                                              defaultValue={this.state.site?.name}
                                                >
                                                    <option>Choisir un site</option>
                                                    {
                                                        this.state.company.sites?.map(site => {
                                                            return (
                                                                <option key={site.ref} value={site.ref}>
                                                                    {site.name}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row}>
                                            <Form.Label column md={4}>Nom de votre
                                                salle</Form.Label>
                                            <Col md={8}>

                                                <Form.Control type="text"
                                                              required
                                                              isInvalid={!this.state.createdRoom.name}
                                                              onChange={this.handleNameChange.bind(this)}
                                                />

                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row}>
                                            <Form.Label md={4} column>Type de la salle</Form.Label>
                                            <Col md={8}>

                                                <Form.Control as="select"
                                                              required
                                                              placeholder='Type de salle'
                                                              value={this.state.createdRoom.config?.type}
                                                              onChange={this.handleSelectType.bind(this)}>
                                                    <option>Choisir le type de salle</option>
                                                    <option value={RoomConfigTypeEnum.OpenSpace}>Openspace</option>
                                                    <option value={RoomConfigTypeEnum.Office}>Bureau individuel</option>
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>

                                        <Row>
                                            <Col className={"align-self-start"}>
                                                <Link to={{
                                                    pathname: "/boarding/site",
                                                    state: {company: this.state.company}
                                                }}>
                                                    <FontAwesomeIcon icon={faCaretLeft}/> Créer un site
                                                </Link>
                                            </Col>
                                            <Col md={"auto"}>
                                                <Button block variant={"primary"}
                                                        type="submit"
                                                        disabled={!this.state.createdRoom.config || !this.state.createdRoom.name}
                                                >
                                                    Inviter des collègues à vous rejoindre
                                                </Button>
                                            </Col>

                                        </Row>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    );
                }
                }
            </UserContext.Consumer>
        );
    }

    private handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        let room = this.state.createdRoom;
        room.name = event.target.value;
        this.setState({
            createdRoom: room,
        });
    }

    private handleSelectWorkplace = (event: ChangeEvent<HTMLInputElement>) => {
        let siteRef = event.target.value;
        if (siteRef) {
            let site = this.state.company.sites?.find(site => site.ref === siteRef);
            this.setState({
                site: site
            })
        } else {
            this.setState({
                site: undefined
            })
        }
    }

    private handleSelectType = (event: ChangeEvent<HTMLInputElement>) => {
        let roomtype = this.roomConfigMap.get(event.target.value)?.type;
        if (!roomtype) {
            roomtype = RoomConfigTypeEnum.OpenSpace;
        }
        let room = this.state.createdRoom;
        room.config = {type: roomtype};
        this.setState({
            createdRoom: room,
        });
    };

    updateCompanyWithRoom = async (updateUserInCtx: (user: User, auth?: Authentication) => void) => {
        let u = this.userService.getLocalProfile();
        const room = {owner: u, ...this.state.createdRoom};

        let company = this.state.company;
        let site = company.sites?.find(site => site.name === this.state.site?.name);
        if (site) {
            if (site.rooms) {
                if (!site.rooms.find(r => r.name === room.name)) {
                    site.rooms.push(room);
                }
            } else {
                site.rooms = [room];
            }
        }

        let updatedRoom = await this.companyService.createCompanyRoom(company);
        if (updatedRoom.ref) {
            u.office = updatedRoom.ref;
            u.company = company.ref;
            await this.userService.updateUser(u);
            this.setState({
                company: company,
                createdRoom: updatedRoom,
                error: false,
            });
            updateUserInCtx(u);
        } else {
            toast.error("Une erreur est survenue");
            throw new Error("Une erreur est survenue");
        }


    }

    private registerUserAndMoveToNextStep(e: FormEvent, updateUserInCtx: (user: User, auth?: Authentication) => void) {
        e.preventDefault();
        this.updateCompanyWithRoom(updateUserInCtx)
            .then(() => this.props.history.push({
                    pathname: "/boarding/invite"
                }, {company: this.state.company, room: this.state.createdRoom} as CompanyRoom,
            ))
            .catch((e) => {
                this.logger.error("Erreur à la création de la salle: " + e);
                this.setState({error: true})
            });


    }
}

const RoomCreation = wrapOnBoarding(RoomCreationContent)
export default RoomCreation;