import {
    AccountResourceApi,
    ApiCallMethodEnum,
    CompanyResourceApi,
    ConfigurationResourceApi,
    UserResourceApi
} from "hgr-api";
import {AppConfig} from "../components/config/constants";
import globalAxios, {AxiosRequestConfig} from "axios";
import {EtablissementApi, UniteLegaleApi} from "sirene-api";

class ApiService {

    public getUserApi = () => {
        return new UserResourceApi(undefined, AppConfig.ENVIRONMENT.apiUrl);
    }

    public getCompanyApi = () => {
        return new CompanyResourceApi(undefined, AppConfig.ENVIRONMENT.apiUrl);
    }

    public getConfigurationApi = () => {
        return new ConfigurationResourceApi(undefined, AppConfig.ENVIRONMENT.apiUrl);
    }

    public getAccountApi = () => {
        return new AccountResourceApi(undefined, AppConfig.ENVIRONMENT.apiUrl);
    }

    public callApi = (method: ApiCallMethodEnum | undefined, url: string | undefined, body?: any) => {
        let axiosRequestArgs: AxiosRequestConfig = {
            method: method,
            url: AppConfig.ENVIRONMENT.apiUrl + url,
            data: body
        };
        return globalAxios.request(axiosRequestArgs);
    }
}

class InseeAPIService {

    public getUniteLegaleApi = () => {
        return new UniteLegaleApi(undefined, AppConfig.ENVIRONMENT.inseeApiUrl);
    }

    public getEtablissementApi = () => {
        return new EtablissementApi(undefined, AppConfig.ENVIRONMENT.inseeApiUrl);
    }

    public callApi = (method: ApiCallMethodEnum | undefined, url: string | undefined, body?: any) => {
        let axiosRequestArgs: AxiosRequestConfig = {
            method: method,
            url: AppConfig.ENVIRONMENT.inseeApiUrl + url,
            data: body
        };
        return globalAxios.request(axiosRequestArgs);
    }
}

const API = new ApiService();
export const InseeAPI = new InseeAPIService();

export default API;