import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Company} from "hgr-api";
import {faBuilding} from "@fortawesome/free-solid-svg-icons";

type CompanyInfoProps = {
    company: Company
}

class CompanyInfoPane extends Component<CompanyInfoProps> {

    render() {
        let logoUrl = this.props.company?.logo??'/img/company-default-logo.png';
        return (
            <div className="company-info w-100">
                { logoUrl ?
                    <img alt="logo" src={logoUrl}  className="drawerLogo" /> :
                    <FontAwesomeIcon icon={faBuilding} className="drawerLogo" /> }
                <button className="CompanyNameButton">{this.props.company?.name}</button>
                {/*<input
                    type="text"
                    className="searchRoomInput"
                    placeholder="Search user, room, stage and workplace"
                />*/}
            </div>
        );
    }
}

export default CompanyInfoPane;