import React, {Component} from 'react';
import $ from "jquery";

import './MediaView.scss';
import {LoggerFactory} from 'components/utils/ConfigLog4j';
import {AnimationUtils} from './AnimationUtils';
import {CompanyRoom, RoomConfigViewModeEnum, User} from "hgr-api";
import {AppConfig} from "../config/constants";
import {JitsiMeetExternalAPI, ParticipantInfo} from "../../model/jitsi";
import {UserService} from "../../services/user-service";

export type VideoConferenceInfo = {
    confLink?: string,
    participants?: ParticipantInfo[],
}

export type MediaViewProps = {
    user: User,
    currentRoom: CompanyRoom,
    conferenceToken: string | undefined,
    onVideoConferenceJoined: (videoConfInfo: VideoConferenceInfo) => void,
    onParticipantLeft?: (participantInfo: { id: string }) => void
    onParticipantJoined?: (participantInfo: ParticipantInfo) => void
}

export type MediaViewState = {
    jitsiApi?: JitsiMeetExternalAPI,
    failedToLoadJitsi?: boolean,
}

class MediaView extends Component<MediaViewProps, MediaViewState> {

    private logger = LoggerFactory.getLogger("hgr.MediaView");

    private userService: UserService = new UserService();

    constructor(props: MediaViewProps) {
        super(props);
        this.state = {failedToLoadJitsi: false};
    }

    componentDidUpdate(prevProps: Readonly<MediaViewProps>, snapshot?: any) {
        this.logger.trace(() => "nextProps: " + JSON.stringify(this.props) + ", prevstate: " + JSON.stringify(prevProps));
        if ( prevProps.currentRoom.ref !== this.props.currentRoom.ref) {
            this.recreateJitsi(this.props.currentRoom, this.props.user, this.props.conferenceToken);
        }
    }

    render() {

        if (this.state.failedToLoadJitsi) {
            return (<div className={"loading-failed"}>
                <h1>Une erreur est survenue lors du chargement de la vidéo salle.</h1>
            </div>);
        } else {
            return (
                <div className="media-frame">
                    <div id="jitsi"/>
                </div>
            );
        }
    }

    /**
     * Attach the script to this component.
     *
     * @returns {void}
     */
    componentDidMount() {
        this.recreateJitsi(this.props.currentRoom, this.props.user, this.props.conferenceToken);
    }

    private readonly onVideoConferenceJoined = (info: {roomName: string}, api?: JitsiMeetExternalAPI) => {
        if (api) {
            let participantsInfo = api.getParticipantsInfo();
            let url = `https://${AppConfig.ENVIRONMENT.webconfDomain}/${info.roomName}`;
            api.executeCommand("setTileView", {enabled: this.props.currentRoom.config?.viewMode === RoomConfigViewModeEnum.Grid});
            this.props.onVideoConferenceJoined({confLink: url, participants: participantsInfo});
        }
        AnimationUtils.removeAnimation($(".loader-parent"));
    };

    private recreateJitsi(room: CompanyRoom, user: User, conferenceToken: string | undefined) {
        if (!AppConfig.ENVIRONMENT.jitsiView) {
            this.logger.info("The MediaView is deactivated per configuration");
            return;
        }
        this.logger.info("Recreating Jitsi frame for room: " + room.ref);

        AnimationUtils.loadAnimation($(".loader-parent"), "moveToRoom.mp4");

        const parentNode = document.querySelector<Element>('#jitsi') as Element;


        this.logger.trace("got the parentnode " + parentNode.innerHTML) ;

        while (parentNode.firstChild) {
            parentNode.firstChild.remove();
        }

        const script = document.createElement('script');

        script.async = true;
        script.onload = () => {
            this.logger.debug("Script on load called");
            let api = this.createJitsiApi(room, user, conferenceToken);
            if (api) {
                api.on("videoConferenceJoined", (info) => this.onVideoConferenceJoined(info, api));
            } else {
                this.setState({failedToLoadJitsi: true});
            }
        };
        script.src = "https://" + AppConfig.ENVIRONMENT.webconfDomain + "/external_api.js";

        parentNode.appendChild(script);
    }

    private createJitsiApi(room: CompanyRoom, user: User, conferenceToken: string | undefined): JitsiMeetExternalAPI | undefined {

        this.logger.info("Create JitsiAPI for room: " + room.ref + " and user: " + user.name);
        const JitsiMeetExternalAPI = (window as any).JitsiMeetExternalAPI;
        
        const configOverwrite = {
            startWithAudioMuted: !room.config?.startWithMicOn,
            startWithVideoMuted: room.config?.cameraMode !== 'CAMERA',
            defaultLanguage: 'fr',
            prejoinPageEnabled: false,
            enableWelcomePage: false,
            disableInviteFunctions: true,
            enableReactions: true,
            disablePolls: true,
            toolbarButtons: [
                'microphone',
                'camera',
                'closedcaptions',
                'desktop',
                'fullscreen',
                'fodeviceselection',
                'profile',
                'info',
                'chat',
                'recording',
                'livestreaming',
                'etherpad',
                'sharedvideo',
                'raisehand',
                'videoquality',
                'filmstrip',
                'feedback',
                "select-background",
                'stats',
                'shortcuts',
                'tileview',
                'videobackgroundblur',
                'download',
                'help',
                'mute-everyone',
                'invite'
            ],
        };

        const parentNode = document.querySelector<Element>('#jitsi') as Element;

        let userAvatar = this.userService.getUserAvatar(user);

        let jitsi : JitsiMeetExternalAPI = new JitsiMeetExternalAPI(AppConfig.ENVIRONMENT.webconfDomain, {
            roomName: room?.ref ?? 'hologramme',
            configOverwrite: configOverwrite,
            interfaceConfigOverwrite: {
                DEFAULT_LOCAL_DISPLAY_NAME: "moi",
                DEFAULT_REMOTE_DISPLAY_NAME: "Invité",
                LANG_DETECTION: true,
                SHOW_JITSI_WATERMARK: false,
                SHOW_WATERMARK_FOR_GUESTS: false,
                SHOW_BRAND_WATERMARK: false,
                APP_NAME: "Hologramme",
                MOBILE_APP_PROMO: false,
                SHOW_CHROME_EXTENSION_BANNER: false,
                ENFORCE_NOTIFICATION_AUTO_DISMISS_TIMEOUT: 15000,

            },
            parentNode: parentNode,
            userInfo: {
                displayName: user.name,
                email: user.email,
                avatar: userAvatar,
            },
            jwt: AppConfig.ENVIRONMENT.useJwt ? conferenceToken : null
        });

        if (jitsi) {

            let commands = {
                displayName: user.name,
                avatarUrl: [userAvatar],
                subject: room?.name,
            };
            jitsi.on('videoConferenceJoined', info => {
                jitsi.executeCommands(commands);
            } );

            if (!AppConfig.ENVIRONMENT.useJwt) {
                // set new password for channel
                jitsi.on('participantRoleChanged', (event: any) => {
                    if (event.role === "moderator") {
                        jitsi.executeCommand('password', room.config?.password);
                    }
                });
                // join a protected channel
                jitsi.on('passwordRequired', () =>
                {
                    jitsi.executeCommand('password', room.config?.password);
                });
            }

            this.manageUsersPresence(jitsi);

            return jitsi;
        }
    }

    private manageUsersPresence(jitsi: JitsiMeetExternalAPI) {
        // Manage user presence
        jitsi.on("participantJoined", (info: { id: string, displayName: string }) => {
            let jitsiUsers: ParticipantInfo[] = jitsi.getParticipantsInfo();
            this.logger.debug("participantJoined: Liste des participants => " + JSON.stringify(jitsiUsers));
            let participantInfo = jitsiUsers.find((ju: ParticipantInfo) => ju.participantId === info.id);
            if (this.props.onParticipantJoined && participantInfo) {
                this.props.onParticipantJoined(participantInfo);
            }
        });

        jitsi.on("participantLeft", (info: { id: string }) => {
            this.logger.debug("participantLeft: Participant => " + JSON.stringify(info));
            if (this.props.onParticipantLeft) {
                this.props.onParticipantLeft(info);
            }
        });
    }
}

export default MediaView;