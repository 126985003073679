import React, {Component} from 'react';
import HgrWelcomeHeader from "./welcome/HgrWelcomeHeader";
import {RouteComponentProps} from "react-router-dom";


function wrapOnBoarding<T>(WrappedComponent: React.ComponentType<T>) {

    return class extends Component<RouteComponentProps & T> {
        render() {
            return (
                <div className="boarding">
                    <HgrWelcomeHeader {...this.props as RouteComponentProps}/>
                    <WrappedComponent {...this.props as T}/>
                </div>
            );
        }
    }
}

export default wrapOnBoarding;
