import React, {Component} from 'react';
import {Button} from "react-bootstrap";
import classNames from "classnames";
import {Company, User} from "hgr-api";
import UserProfile from "./UserPofile";
import {UserService} from "../../../services/user-service";

type Props = {
    user: User,
    company: Company,
}

type State = {
    showProfile: boolean,
    profileChanged: boolean,
}

class ConfigureUserProfile extends Component<Props, State> {

    private userService: UserService = new UserService();

    constructor(props: Props) {
        super(props);
        this.state = {
            showProfile: false,
            profileChanged: false,
        }
    }

    render() {
        let avatar = this.userService.getUserAvatar(this.props.user);
        return (
            <>

                <Button className={classNames("hgr-icon-circle", "avatar")}
                        style={{ backgroundImage: `url(${avatar})` }}
                        onClick={() => this.setState({showProfile: true})}>
                </Button>

                <UserProfile user={this.props.user}
                             company={this.props.company}
                             onSave={() => this.setState({showProfile: false})}
                             showProfile={this.state.showProfile}
                             onClose={() => this.setState({showProfile: false})}/>
            </>
        );
    }

}

export default ConfigureUserProfile;