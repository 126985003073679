import 'typeface-roboto';
import './App.scss';

import React, {Component} from 'react';

import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {UserContext, UserProfileCtx} from 'components/utils/UserContext';
import {Authentication, SecurityContext} from 'components/utils/SecurityContext';
import {StorageService} from "./services/storage-service";
import {STORAGE_HGR_AUTH_KEY, STORAGE_HGR_USER_KEY} from "./components/config/constants";
import HgrRoutes from "./HgrRoutes";
import {User} from 'hgr-api';
import {ToastContainer} from "react-toastify";

type Props = {}

type State = {
    user?: User,
    auth: Authentication,
}

class App extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        let authStr = StorageService.local.get(STORAGE_HGR_AUTH_KEY);
        let userStr = StorageService.local.get(STORAGE_HGR_USER_KEY);

        this.state = {
            user: userStr ? JSON.parse(userStr) : null,
            auth: authStr ? JSON.parse(authStr) : null,
        };

        this.setAppState.bind(this);
    }

    setAppState(user: User, auth: Authentication) {

        let newState = {} as State;
        if (user) {
            newState.user = user;
            StorageService.local.set(STORAGE_HGR_USER_KEY, JSON.stringify(user));
        } else {
            newState.user = undefined;
            StorageService.local.remove(STORAGE_HGR_USER_KEY);
        }
        if (auth) {
            newState.auth = auth;
            StorageService.local.set(STORAGE_HGR_AUTH_KEY, JSON.stringify(auth));
        }

        this.setState(newState);
    };


    render() {
        return (
            <>
                <SecurityContext.Provider value={this.state.auth}>
                    <UserContext.Provider
                        value={{user: this.state.user, setAppState: this.setAppState.bind(this)} as UserProfileCtx}>
                        <Router>
                            <Switch>
                                <Route exact path="/">
                                    {
                                        this.isAlreadyRegistered() ?
                                            <Redirect to={{pathname: "/home"}}/> :
                                            <Redirect to={{pathname: "/welcome"}}/>
                                    }
                                </Route>
                                <HgrRoutes/>
                            </Switch>
                        </Router>
                    </UserContext.Provider>
                </SecurityContext.Provider>

                <ToastContainer containerId={"hgr-default-notifications"} enableMultiContainer={true}/>
            </>
        );
    }

    private isAlreadyRegistered() {
        console.log(JSON.stringify(this.state));
        return this.state.user != null && this.state.user.company != null && this.state.user.office != null;
    }
}

export default App;
