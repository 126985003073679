import React, {Component} from 'react'


import {LoggerFactory} from "../../utils/ConfigLog4j";
import {Button, Col, Form, Row} from "react-bootstrap";
import {toast} from "react-toastify";
import {TOAST_ONBOARDING_ID} from "../../config/constants";
import {Link} from "react-router-dom";
import {Company} from "hgr-api";
import {CompanyService} from "../../../services/company-service";
import {AsyncTypeahead} from 'react-bootstrap-typeahead';


type Prop = {}
type State = {
    searchValue: string,
    searchResult: Company[] | undefined,
    loading: boolean,
    toastId?: React.ReactText,
    companySelected?: boolean,
}

class CompanySearchBar extends Component<Prop, State> {

    private logger = LoggerFactory.getLogger("hgr.CompanySearchBar");
    private companyService: CompanyService = new CompanyService();


    constructor(props: Prop) {
        super(props);
        this.state = {} as State;
    }

    render() {
        return (
            <>
                <Form>

                    <Form.Group>
                        <AsyncTypeahead
                            className={"inputsBox"}
                            size={"large"}
                            labelKey={c => c.name??""}
                            filterBy={() => true}
                            isLoading={this.state.loading}
                            minLength={3}

                            flip={true}
                            open={this.state.companySelected}
                            highlightOnlyResult={true}
                            onSearch={(query) => this.searchCompanies(query)}
                            options={this.state.searchResult??[]}
                            placeholder="Rechercher une entreprise"
                            renderMenuItemChildren={(option) => (
                                <ResultLine company={option as Company}/>
                            )}
                        />
                    </Form.Group>
                </Form>
                <Row className={"text-white"}>
                    <Col><hr/></Col>
                    <Col className={"col-auto h2"}>OU</Col>
                    <Col><hr/></Col>
                </Row>
                <Row className={"justify-content-center mb-5"}>
                    <Col md={6}>
                        <Link to={{pathname: "/boarding/company"}}>
                            <Button block variant={"primary"} size={"lg"}>
                                Enregistrer votre entreprise
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </>
        )
    }

    private async searchCompanies(searchValue: string) {
        if (searchValue) {
            if (!this.state.loading) {
                try {
                    this.setState({loading: true});
                    let companies = await this.companyService.searchCompany(searchValue);
                    this.setState({searchResult: companies});
                } catch (e: any) {
                    this.logger.error("Error on calling the server", e);
                    if (this.state.toastId) {
                        toast.update(this.state.toastId, {render: () => `L'application ne semble pas disponible en ce moment.`});
                    } else {
                        let toastId = toast(`L'application ne semble pas disponible en ce moment.`, {toastId: TOAST_ONBOARDING_ID});
                        this.setState({toastId: toastId});
                    }
                } finally {
                    this.setState({loading: false});
                }
            }
        } else {
            this.setState({searchResult: undefined});
        }
    }
}

class ResultLine extends Component<{company: Company}> {
    render() {
        let { company } = this.props;
        return <Link key={company.ref} to={{
                         pathname: "/boarding/search-room",
                         state: {companyRef: company.ref}
                     }}>
            <div className="inputsBoxdivRowLine" key={company.ref}>
                <div className="inputsBoxdivrow">
                    {this.renderCompanyLogo()}
                    <div className="verticalColumn">
                        <span className="greenTextBold">{company.name}</span>
                        {company.address &&
                        <span className="smallTextBold">{company.address}</span>}
                    </div>
                </div>
                <span className="greenTextBold">{company.siren}</span>
            </div>
        </Link>;
    }

    private renderCompanyLogo() {
        let { company } = this.props;
        if (company.logo) {
            return <img src={company.logo} className="smallLogo" alt={company.name}/>;
        } else {
            return <img src={"/img/company-default-logo.png"} className="smallLogo" alt={company.name}/>;
        }
    }
}

export default CompanySearchBar;
