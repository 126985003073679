import "./search-rooms.scss";
import React, {Component} from "react";
import RoomsBox from "./RoomsBox";
import {RoomInfo} from "../../model/hgr-company";
import wrapOnBoarding from "../OnBoarding";
import {Redirect, RouteChildrenProps} from "react-router";
import {Company, RoomConfigTypeEnum} from "hgr-api";
import API from "../../../services/api-service";
import {LoggerFactory} from "../../utils/ConfigLog4j";
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";

type RoomSearchState = {
    companyRef: string,
    company?: Company,
    searchText?: string,
    filterSite?: string,
    filterType?: string,
    filterOwner?: string
}

const ROOM_TYPES = [RoomConfigTypeEnum.OpenSpace];

class RoomSearchInner extends Component<RouteChildrenProps, RoomSearchState> {

    private logger = LoggerFactory.getLogger("hgr.RoomSearchInner");

    constructor(props: Readonly<RouteChildrenProps>) {
        super(props);

        this.logger.debug(() => "State from router: " + JSON.stringify(props.location?.state));
        this.state = this.props.location?.state as RoomSearchState;
    }

    async componentWillMount() {
        if (this.state) {
            let companyApi = API.getCompanyApi();
            if (this.state.companyRef) {
                let companyResp = await companyApi.company({companyRef: this.state.companyRef });
                let company = companyResp.data as Company;
                this.logger.debug(() => "Société récupérée: " + JSON.stringify(company));
                this.setState({company: company});
            } else {
                throw new Error("L'entreprise n'a pas été sélectionnée.");
            }
        }
    }

    render() {
        if (!this.state) {
            return (<Redirect to={"/"}/>);
        }
        return (
            <div className="search-rooms">
                {
                    this.state.company && this.state.company.sites?.map(site => {
                        this.logger.debug("Site: " + JSON.stringify(site));

                        return site.rooms?.filter(room => ROOM_TYPES.find(t => t === room.config?.type))
                            .map(room => (
                            <RoomsBox key={site.ref + "_" + room.ref}
                                circle="/img/circle1.PNG"
                                square={this.getImageForType(room.config?.type)}
                                roomInfo={
                                    {room: room, site: site} as RoomInfo
                                }
                            />
                        ))
                    })
                }

                <div className="footCenterView">

                    <div className="bottom-buttons">
                        <Link to="/welcome">&lt; Rechercher une entreprise</Link>
                        <Button variant={"success"} className="butStyle"
                                onClick={() => this.props.history.push({
                                    pathname: "/boarding/room" },
                                    { company: this.state.company }
                                )}>
                            Enregistrer une salle
                        </Button>
                    </div>

                </div>
            </div>
        );
    }

    // noinspection JSMethodCanBeStatic
    private getImageForType(type?: RoomConfigTypeEnum) {
        switch (type) {
            case RoomConfigTypeEnum.Office:
                return "/img/search/desktop.png";
            case RoomConfigTypeEnum.OpenSpace:
                return "/img/search/openspace.png";
            default:
                return "/img/search/meeting.png";
        }
    }
}

const RoomSearch = wrapOnBoarding(RoomSearchInner);
export default RoomSearch;