import React, {Component} from 'react';
import RoomPane from './RoomPane';
import {CompanyRoom, User} from "hgr-api";
import {RoomGroup} from "./CompanySitePane";
import {LoggerFactory} from "../../utils/ConfigLog4j";
import {RoomInfo} from "../../model/hgr-company";
import classNames from "classnames";

type RoomGroupProps = {
    group: RoomGroup,
    user: User,
    currentPosition: RoomInfo,
    onRoomActivate: (room: CompanyRoom, conferenceToken: string | undefined) => void,
}


class RoomGroupPane extends Component<RoomGroupProps> {

    private logger = LoggerFactory.getLogger("hgr.RoomGroupPane");

    constructor(props: RoomGroupProps) {
        super(props);
        this.logger.debug("Rendering RoomGroupPane for: " + this.props.group.groupName + " / " + this.props.group.type);
    }


    render() {
        let roomGroup = this.props.group;
        if (roomGroup.rooms.length > 0) {
            let currentRoom = this.props.currentPosition.room;
            let groupStateClassName = roomGroup.rooms.find(r => r.ref === currentRoom.ref) ? "user-inside" : "";

            return (
                <div className={classNames("room-group", groupStateClassName)} key={roomGroup.type}>
                    <div className={"heading"}>
                        <span>{roomGroup.groupName}</span>
                    </div>
                    {roomGroup.rooms.map(room =>
                        <RoomPane key={room.ref}
                                  room={room}
                                  user={this.props.user}
                                  active={this.props.user.currentRoom === room.ref}
                                  onRequestAccess={(answer) => this.props.onRoomActivate(room, answer.conferenceToken)}/>)}
                </div>
            );
        } else {
            return (<></>);
        }
    }
}

export default RoomGroupPane;