import React, {Component} from 'react';
import {CompanyRoom, CompanyRoomStateEnum, RoomAccessResponse, RoomConfigNameModeEnum, User} from "hgr-api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDoorClosed, faDoorOpen, faMapMarkerAlt, faUserCircle} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import {LoggerFactory} from "../../utils/ConfigLog4j";
import {Logger} from "typescript-logging";
import {CompanyService} from "../../../services/company-service";
import {UserService} from "../../../services/user-service";
import {Badge} from "react-bootstrap";

type RoomInfoProps = { 
    room: CompanyRoom,
    user: User,
    active?: boolean,
    onRequestAccess: (answer : RoomAccessResponse) => void,
}

type RoomInfoState = {
    expanded: boolean
}

class RoomPane extends Component<RoomInfoProps, RoomInfoState> {
    private logger: Logger = LoggerFactory.getLogger("hgr.RoomPane");
    private companyService: CompanyService = new CompanyService();
    private userService: UserService = new UserService();

    constructor(props: RoomInfoProps) {
        super(props);
        this.state = {
            expanded: this.props.active ?? false,
        }
    }

    componentDidUpdate(prevProps: Readonly<RoomInfoProps>) {
        if (this.props.active !== prevProps.active) {
            this.setState({
                expanded: this.props.active ?? false,
            });
        }

    }

    render() {
        let participantsNumber = this.participantsNumber;
        return (
            <div key={this.props.room.ref} className="room-pane bg-secondary">
                <div className="row" onClick={this.toggleRoom.bind(this)}>
                    <div className={"room-name"}>
                        {this.props.room.name}
                        {
                            (participantsNumber > 0)
                            && <Badge className="room-number-participants" variant={"info"}>
                                {participantsNumber}
                            </Badge>
                        }
                    </div>
                    {
                        <div className={classNames({
                            "room-state": true,
                            "active": this.props.active,
                            "locked" : this.props.room.state === CompanyRoomStateEnum.Locked,
                        })} onClick={this.requestAccessToRoom.bind(this)}>
                            <FontAwesomeIcon icon={this.getIcon()}/>
                        </div>
                    }

                </div>
                {
                    this.state.expanded && this.renderParticipants()
                }
            </div>

        );
    }

    private getIcon() {
        if (this.props.active) {
            return faMapMarkerAlt;
        } else if (this.props.room.state === CompanyRoomStateEnum.Opened || this._isCurrentUserRoom()){
            return faDoorOpen;
        } else if (this.props.room.state === CompanyRoomStateEnum.Locked){
            return faDoorClosed;
        } else if (this.props.room.state === CompanyRoomStateEnum.Closed){
            return faDoorClosed;
        } else {
            return faDoorOpen;
        }
    }

    private _isCurrentUserRoom() {
        return this.props.room.occupants?.find(u => u.ref === this.props.user.ref);
    }

    private renderParticipants() {
        if (this.props.room.state !== CompanyRoomStateEnum.Locked
            && this.props.room.participants
            && this.props.room.participants.length > 0) {
            let displayNickname = this.props.room.config?.nameMode === RoomConfigNameModeEnum.Alias;
            return (
                <div className="users">
                    <span className="colorGreen">Participants:</span>
                    {
                        this.props.room.participants.map(user => {
                            let userAvatar = this.userService.getUserAvatar(user);
                            let displayName = displayNickname ? user.nickName : user.name ;
                            return (<UserInfo key={user.ref} logo={userAvatar} name={displayName} />)}
                        )
                    }
                </div>
            );
        }
    }

    get participantsNumber(): number {
        if (this.props.room.participants) {
            return this.props.room.participants.length;
        } else {
            return 0;
        }
    }

    private toggleRoom() {
        this.setState({
            expanded: !this.state.expanded
        })
    }

    private async requestAccessToRoom() {
        if (!this.props.active) {
            let room = this.props.room;
            this.logger.info("Requesting access to room: " + room.name);
            await this.companyService.enterRoom(room, this.props.onRequestAccess);
        }
    }
}

type UserInfoProps = {
    logo?: string,
    name?: string,
}

class UserInfo extends Component<UserInfoProps> {
    render() {
        const { logo, name } = this.props;
        return (
            <div className="startRow">
                {
                    logo
                        ? <img src={logo} className="userAvatar" alt={name}/>
                        : <FontAwesomeIcon icon={faUserCircle} className={"userAvatar"} />
                }
                <span className="userName">{name ?? "Anonyme"}</span>
            </div>
        );
    }
}

export default RoomPane;