import { SocialLoginAccessToken } from "components/model/hgr-user";
import moment from 'moment';
import {User, UserIdentityProviderEnum} from "hgr-api";
import {HGR_CUSTOM_HEADER_JWT, STORAGE_HGR_AUTH_KEY, STORAGE_HGR_USER_KEY} from "../components/config/constants";
import {LoggerFactory} from "../components/utils/ConfigLog4j";
import {Logger} from "typescript-logging";
import {StorageService} from "./storage-service";
import {toast} from "react-toastify";
import {History} from "history"
import API from "./api-service";
import {WebsocketService} from "./websocket-service";


export const ACTION_TYPES = {
  LOGIN: 'authentication/LOGIN',
  GET_SESSION: 'authentication/GET_SESSION',
  LOGOUT: 'authentication/LOGOUT',
  CLEAR_AUTH: 'authentication/CLEAR_AUTH',
  ERROR_MESSAGE: 'authentication/ERROR_MESSAGE'
};

export const AUTH_TOKEN_KEY = 'hgr-authToken';

export class AuthService {
  
  private logger: Logger = LoggerFactory.getLogger("hgr.AuthService");

  private websocketService: WebsocketService = new WebsocketService();
  
  /**
   * validateProviderToken
   */
  public async authenticateWithProviderToken(provider: UserIdentityProviderEnum, token: SocialLoginAccessToken): Promise<User> {
    let expiresAt = moment(token.expiresAt);
    let now = moment();
    if (expiresAt.isBefore(now)) {
      this.logger.trace("ExpiresAt: " + expiresAt.format());
      this.logger.trace("NOW: " + moment().format());
      throw new Error("Token invalid");
    }
    // call the server :
    // * validate the provider token
    // * get a temporary (inscription JWT)
    let api = API.getUserApi();
    let response = await api.authenticate({ userIdentity: {
        provider: provider,
        token: token.idToken,
      }
    });
    this.logger.trace(JSON.stringify(response));
    let jwt = response.headers[HGR_CUSTOM_HEADER_JWT];
    if (jwt) {
      this.logger.debug("JWT Token: " + jwt);
      StorageService.local.set(AUTH_TOKEN_KEY, jwt);
    } else {
      throw new Error("Echec de l'autentification");
    }
    return response.data;
  }

  public async authenticateWithLogin(login: string, password: string, rememberMe: boolean): Promise<User> {
    // call the server :
    // * validate the provider token
    // * get a temporary (inscription JWT)
    let api = API.getUserApi();
    let response = await api.authenticate({ userIdentity: {
        provider: UserIdentityProviderEnum.Hgr,
        login: login,
        password: password,
      }
    });
    this.logger.trace(JSON.stringify(response));
    let jwt = response.headers[HGR_CUSTOM_HEADER_JWT];
    if (jwt) {
      this.logger.debug("JWT Token: " + jwt);
      StorageService.local.set(AUTH_TOKEN_KEY, jwt);
    } else {
      throw new Error("Echec de l'autentification");
    }
    return response.data;
  }


  public clearAuthToken = () => {
    this.logger.info("Clearing the JWT from the storage");
    this.clearStorageKey(AUTH_TOKEN_KEY);
  };

  public clearHgrUser = () => {
    this.logger.info("Clearing the JWT from the storage");
    this.clearStorageKey(STORAGE_HGR_USER_KEY);
    this.clearStorageKey(STORAGE_HGR_AUTH_KEY);
  };

  private clearStorageKey(key: string) {
    if (StorageService.local.get(key)) {
      StorageService.local.remove(key);
    }
    if (StorageService.session.get(key)) {
      StorageService.session.remove(key);
    }
  }

  public logout = async () => {
    this.clearAuthToken();
    this.clearHgrUser();
    this.websocketService.disconnect();
  };

  public clearAuthenticationOnError = async (history?: History, message: string = "Une erreur de sécurité s'est produite.") => {
    await this.logout();
    if (history && history.location.pathname !== "/login") {
      history.push("/access-denied");
    } else {
      toast.error(message);
    }
  };
}