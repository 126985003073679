import React, { useContext } from "react";
import { Authentication } from "./SecurityContext";
import { User } from "hgr-api";

export type UserProfileCtx = {
  user: User,
  setAppState: (user?: User, auth?: Authentication) => void,
}

export const UserContext = React.createContext<UserProfileCtx>({} as UserProfileCtx);

export function useUserContext() {
  return useContext(UserContext);
}
