import React, {Component} from 'react';
import {Col, Row} from "react-bootstrap";

type Props = {
    description: string,
    title: string,
    image?: string,
    inverted: boolean,
}

export class Step extends Component<Props> {

    render() {
        return (
            <Row className={"justify-content-between m-4"}>
                <Col md={6} className={this.props.inverted ? "order-1" : "order-12"}>
                    <div className="step1View">
                        <h2>{this.props.title}</h2>
                        <p className="text">
                            {this.props.description}
                        </p>
                    </div>
                </Col>
                <Col md={6} className={!this.props.inverted ? "order-1" : "order-12"}>

                </Col>
            </Row>
        );

    }
}
