import React, {Component} from 'react';
import {CompanyRoom, CompanySite, RoomConfigTypeEnum, User} from "hgr-api";
import {LoggerFactory} from "../../utils/ConfigLog4j";
import RoomGroupPane from "./RoomGroupPane";
import {RoomInfo} from "../../model/hgr-company";
import classNames from "classnames";


export type RoomGroup = {
    type: RoomConfigTypeEnum,
    groupName: string,
    rooms: CompanyRoom[],
}

type Prop = {
    site: CompanySite,
    user: User,
    onRoomActivate: (room: CompanyRoom, conferenceToken: string | undefined) => void,
    currentPosition: RoomInfo,
}

type State = {
    roomGroups : RoomGroup[]
}

class CompanySitePane extends Component<Prop, State> {

    private logger = LoggerFactory.getLogger("hgr.CompanySitePane");

    constructor(props: Prop) {
        super(props);
        if (this.props.site.rooms && this.props.site.rooms.length > 0) {
            this.logger.debug("Creating roomGroups information for Site: " + this.props.site.name);
            let groups = CompanySitePane.createRoomGroupMap(this.props.site.rooms);

            this.state = {
                roomGroups: Array.from(groups.values())
            }

            this.logger.debug(() => "Current state: " + JSON.stringify(this.state));
        }
    }

    static getDerivedStateFromProps(nextProps: Prop, prevState: State) {
        if (nextProps && nextProps.site.rooms) {
            let roomGroupMap = CompanySitePane.createRoomGroupMap(nextProps.site.rooms);
            return {
                roomGroups: Array.from(roomGroupMap.values())
            }
        }
    }

    private static createRoomGroupMap(rooms: CompanyRoom[]) : Map<RoomConfigTypeEnum, RoomGroup> {
        let groups: Map<RoomConfigTypeEnum, RoomGroup> = new Map<RoomConfigTypeEnum, RoomGroup>();

        rooms.forEach(room => {
            if (room && room.config?.type) {
                let group = groups.get(room.config.type);
                if (!group) {
                    let newGroup: RoomGroup = {
                        groupName: room.config.name ?? room.config.type.toString(),
                        type: room.config.type,
                        rooms: [room]
                    };
                    groups.set(room.config.type, newGroup);
                } else {
                    group.rooms.push(room);
                }
            }
        });
        return groups;
    }

    render() {
        if (this.props.site.rooms && this.props.site.rooms.length > 0) {
            let siteStateClassName = this.props.site.ref === this.props.currentPosition.site.ref ? "user-inside" : "";
            return (
                <div className={classNames("company-site", siteStateClassName)}
                     key={this.props.site.ref}>

                    <div className="subHeading">
                        <img src={"/img/building.PNG"} className="building" alt={"company building"}/>
                        <span className="colorWhite">{this.props.site.name}</span>
                    </div>

                    {
                        this.renderRoomGroups()
                    }

                </div>
            );
        } else {
            return (<></>);
        }
    }

    private renderRoomGroups() {
        return this.state.roomGroups.map(group => (
            <RoomGroupPane key={group.groupName}
                           group={group}
                           user={this.props.user}
                           currentPosition={this.props.currentPosition}
                           onRoomActivate={(room, conferenceToken) => this.props.onRoomActivate(room, conferenceToken)}/>
            )
        );
    }
}

export default CompanySitePane;