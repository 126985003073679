import React, {Component} from "react";
import {Col, Form, FormControl} from "react-bootstrap";
import validate from "validate.js";
import {Invitee} from "./ColleaguesInvitation";
import {EMAIL_CONSTRAINTS} from "../config/constants";

type InvitationLineProps = {
    invitee: Invitee,
    onChange: (invitee: Invitee | undefined) => void,
}
type InvitationLineState = {
    fullName?: string,
    fullNameError?: string,
    email?: string,
    emailError?: string,
}

export class InvitationLine extends Component<InvitationLineProps, InvitationLineState> {

    constructor(props: InvitationLineProps) {
        super(props);
        let invitee = this.props.invitee;
        this.state = {
            fullName: invitee.name ?? "",
            email: invitee.email ?? "",
        };
        this.changeEmail.bind(this);
        this.changeName.bind(this);
        this.emitInvitee.bind(this);
    }

    render() {
        return <Form.Row className={"inputsInRow4"}>
            <Col md={4}>
                <FormControl type="text"
                             placeholder='Prénom Nom'
                             className="invitation-field"
                             value={this.state.fullName}
                             onChange={e => this.changeName(e.target.value)}
                             isValid={!!this.state.email && !!this.state.fullName}
                             isInvalid={!!this.state.email && !this.state.fullName}
                />
            </Col>
            <Col md={8}>
                <FormControl type="email"
                             placeholder='Email'
                             className="invitation-field"
                             value={this.state.email}
                             onChange={e => this.changeEmail(e.target.value)}
                             isValid={!this.state.emailError && !!this.state.email}
                             isInvalid={!!this.state.emailError && !!this.state.email}
                />
                {this.state.emailError &&
                <FormControl.Feedback type={'invalid'}>{this.state.emailError}</FormControl.Feedback>}
            </Col>
        </Form.Row>;
    }

    private changeEmail(email: string | undefined) {
        this.setState({email: email});
        this.emitInvitee(this.state.fullName, email);
    }

    private changeName(name: string | undefined) {
        this.setState({fullName: name});
        this.emitInvitee(name, this.state.email);
    }

    private emitInvitee(fullName: string | undefined, email: string | undefined) {
        if (fullName && email) {
            let results = validate.single(email, EMAIL_CONSTRAINTS);
            if (results) {
                let emailError = results[0];
                this.setState({emailError: emailError});
                this.props.onChange(undefined);
            } else {
                this.setState({emailError: undefined, fullNameError: undefined});
                this.props.onChange({name: fullName, email: email, ref: this.props.invitee.ref});
            }
        } else {
            this.props.onChange(undefined);
        }
    }
}