import {AppConfig} from "../config/constants";
import {Logger} from "typescript-logging";
import {LoggerFactory} from "../utils/ConfigLog4j";

export class AnimationUtils {
  
  static videoLoaderDiv = `<div class="video-loader hgr-hidden">
                              <div class="veil hgr-hidden">
                                  <div class="lds-dual-ring"></div>
                              </div>
                          </div>`;
  private static logger: Logger = LoggerFactory.getLogger("hgr.AnimationUtils");


  static loadAnimation(parentDiv: JQuery, videoName: string, onended?: () => void ) {

    if (!AppConfig.ENVIRONMENT.mediaView) {
      AnimationUtils.logger.info("Animations video are deactivated per configuration");
      return;
    }
    parentDiv.append(this.videoLoaderDiv);

    const videoDiv = parentDiv.find('.video-loader');

    const video = document.createElement('video');
    video.src = "/videos/" + videoName;
    video.muted = true;
    video.autoplay = true;
    video.loop = false;
    video.controls = false;
    video.width = window.innerWidth;
    video.onended = onended ? onended : () => {
        // Add a loading Veil
        videoDiv.find(".veil").removeClass("hgr-hidden");
    };

    videoDiv.append(video);
    videoDiv.removeClass("hgr-hidden");

  }

  public static removeAnimation(parentDiv: JQuery) {

    if (!AppConfig.ENVIRONMENT.mediaView) {
      AnimationUtils.logger.info("Animations video are deactivated per configuration");
      return;
    }
    let videoDiv = parentDiv.children(".video-loader");
    if (videoDiv) {
      setTimeout(() => {
        videoDiv.addClass("hgr-hidden");
        videoDiv.find("video")?.remove()
        videoDiv.find(".veil").addClass("hgr-hidden");
        videoDiv.remove();
      }, 1000); 
    }
  }
}