import {AppConfig} from "../components/config/constants";
import {StorageService} from "./storage-service";
import {AUTH_TOKEN_KEY} from "./auth-service";
import {Client, StompSubscription} from '@stomp/stompjs';
import {NotificationService} from "./notification-service";
import {
    Notification, OfficeAssignmentRequestNotification,
    RoomAccessAllowedNotification,
    RoomPositionUpdate,
    RoomUpdateNotification,
    SiteUpdateNotification
} from "hgr-api";
import {BehaviorSubject} from "rxjs";

export class WebsocketService {

    private static client : Client;

    private static subscriptions: StompSubscription[] = [];

    private static _usersPosition: BehaviorSubject<RoomPositionUpdate | undefined> = new BehaviorSubject<RoomPositionUpdate | undefined>(undefined);
    private static _roomUpdates: BehaviorSubject<RoomUpdateNotification | undefined> = new BehaviorSubject<RoomUpdateNotification | undefined>(undefined);
    private static _siteUpdates: BehaviorSubject<SiteUpdateNotification | undefined> = new BehaviorSubject<SiteUpdateNotification | undefined>(undefined);
    private static _roomAccess: BehaviorSubject<RoomAccessAllowedNotification | undefined> = new BehaviorSubject<RoomAccessAllowedNotification | undefined>(undefined);
    private static _officeRequest: BehaviorSubject<OfficeAssignmentRequestNotification | undefined> = new BehaviorSubject<OfficeAssignmentRequestNotification | undefined>(undefined);

    public initWebSocket() {

        let wsUrl = AppConfig.ENVIRONMENT.apiUrl + "/websocket";
        wsUrl = wsUrl.replace(/^http/, 'ws');

        WebsocketService.client = new Client({
            brokerURL: wsUrl,
            connectHeaders: {
                'Authorization': 'Bearer ' + StorageService.local.get(AUTH_TOKEN_KEY),
            },
            debug: function (str) {
                console.log(str);
            },
            reconnectDelay: 5000,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000
        });

        const client = WebsocketService.client;

        client.onConnect = function (frame) {
            // Do something, all subscribes must be done is this callback
            // This is needed because this will be executed after a (re)connect
            let topicsSubscription = client.subscribe("/topic",
                (message) => new NotificationService().notifyUser(message.body));
            let userAsOccupantSubscription = client.subscribe("/user/queue/occupant",
                (message) => {
                    let notification: Notification = JSON.parse(message.body);
                    return new NotificationService().askForAction(notification);
                });

            let usersPositionSubscription = client.subscribe("/user/queue/position",
                (message) => {
                    let positionUpdate: RoomPositionUpdate = JSON.parse(message.body);
                    WebsocketService._usersPosition.next(positionUpdate)
                });

            let roomUpdatesSubscription = client.subscribe("/user/queue/room-update",
                (message) => {
                    let update: RoomUpdateNotification = JSON.parse(message.body);
                    WebsocketService._roomUpdates.next(update);
                });

            let siteUpdatesSubscription = client.subscribe("/user/queue/site-update",
                (message) => {
                    let update: SiteUpdateNotification = JSON.parse(message.body);
                    WebsocketService._siteUpdates.next(update);
                });

            let roomAccessSubscription = client.subscribe("/user/queue/room-access",
                (message) => {
                    let accessAllowed: RoomAccessAllowedNotification = JSON.parse(message.body);
                    WebsocketService._roomAccess.next(accessAllowed);
                });

            let officeRequestSubscription = client.subscribe("/user/queue/office-request",
                (message) => {
                    let officeRequest: OfficeAssignmentRequestNotification = JSON.parse(message.body);
                    new NotificationService().askForAction(officeRequest);
                    WebsocketService._officeRequest.next(officeRequest);
                });

            WebsocketService.subscriptions = [topicsSubscription, userAsOccupantSubscription,
                usersPositionSubscription, roomUpdatesSubscription, siteUpdatesSubscription, roomAccessSubscription,
                officeRequestSubscription];
        };

        client.onStompError = function (frame) {
            // Will be invoked in case of error encountered at Broker
            // Bad login/passcode typically will cause an error
            // Complaint brokers will set `message` header with a brief message. Body may contain details.
            // Compliant brokers will terminate the connection after any error
            console.log('Broker reported error: ' + frame.headers['message']);
            console.log('Additional details: ' + frame.body);
        };

        client.activate();
    }

    public disconnect() {
        WebsocketService.subscriptions.forEach(subscription => subscription.unsubscribe());
        WebsocketService._usersPosition.observers.forEach(observer => observer.complete());
        WebsocketService._roomUpdates.observers.forEach(observer => observer.complete());
        WebsocketService._siteUpdates.observers.forEach(observer => observer.complete());
        WebsocketService._roomAccess.observers.forEach(observer => observer.complete());
        WebsocketService._officeRequest.observers.forEach(observer => observer.complete());
        WebsocketService.client?.deactivate();
    }

    static get usersPosition(): BehaviorSubject<RoomPositionUpdate | undefined> {
        return this._usersPosition;
    }

    static get roomUpdates(): BehaviorSubject<RoomUpdateNotification | undefined> {
        return this._roomUpdates;
    }

    static get siteUpdates(): BehaviorSubject<SiteUpdateNotification | undefined> {
        return this._siteUpdates;
    }

    static get roomAccess(): BehaviorSubject<RoomAccessAllowedNotification | undefined> {
        return this._roomAccess;
    }

    static get officeRequest(): BehaviorSubject<OfficeAssignmentRequestNotification | undefined> {
        return this._officeRequest;
    }
}