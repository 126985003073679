import React from "react";

type Props = {
    value: string
}

type State = {
}

export default class AvatarRenderer extends React.Component<Props, State> {

    render() {
        return <img className={"userAvatar"} src={this.props.value} alt={"avatar"}/>;
    }
}