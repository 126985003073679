import "./home.scss"

import React from "react";
import NavigationPane from "./navigation/NavigationPane";
import MediaView, {VideoConferenceInfo} from "../media/MediaView";
import {CompanyService} from "../../services/company-service";
import {LoggerFactory} from "../utils/ConfigLog4j";
import {UserContext} from "components/utils/UserContext";
import {Redirect} from "react-router-dom";
import {AnimationUtils} from "components/media/AnimationUtils";
import $ from "jquery";
import {
    Company,
    CompanyRoom,
    CompanySite,
    RoomAccessAllowedNotification,
    RoomPositionUpdate,
    RoomUpdateNotification,
    User
} from "hgr-api";
import {StorageService} from "../../services/storage-service";
import {STORAGE_HGR_USER_KEY} from "../config/constants";
import NotificationPane from "./notification/NotificationPane";
import {createBrowserHistory} from "history";
import {Authentication} from "../utils/SecurityContext";
import {WebsocketService} from "../../services/websocket-service";
import {NotificationService} from "../../services/notification-service";
import {ParticipantInfo} from "../../model/jitsi";

type HgrHomeProps = {}

type HgrHomeState = {
    company: Company,
    currentRoom: CompanyRoom,
    currentVRoomLink?: string,
    user: User,
    conferenceToken?: string,
    updateStructure: boolean,
}

class HgrHome extends React.Component<HgrHomeProps, HgrHomeState> {

    private logger = LoggerFactory.getLogger("hgr.HgrHome");

    private notificationService: NotificationService = new NotificationService();
    private companyService: CompanyService = new CompanyService();

    constructor(props: HgrHomeProps) {
        super(props);
        let userStr = StorageService.local.get(STORAGE_HGR_USER_KEY);
        if (userStr) {
            let user: User = JSON.parse(userStr);
            this.state = {user: user} as HgrHomeState;
            let rootDiv = $("#root");

            AnimationUtils.loadAnimation(rootDiv, "entercompany.mp4", () => AnimationUtils.removeAnimation(rootDiv));

        } else {
            this.state = {} as HgrHomeState;
        }
    }

    async componentDidMount() {
        let hgrHomeState = await this.setApplicationState(this.state.user);
        this.setState(hgrHomeState);

        WebsocketService.usersPosition.subscribe(this.updatePosition.bind(this));
        WebsocketService.roomUpdates.subscribe(this.updateRooms.bind(this));
        WebsocketService.roomAccess.subscribe(this.allowEnteringTheRoom.bind(this));
    }

    render() {
        return (
            <UserContext.Consumer>
                {userCtx => {
                    const user = userCtx.user;

                    if (!(user && user.company && user.office)) {
                        this.logger.warn(() => "L'utilisateur est malformé. Retour vers / : " + JSON.stringify(user));
                        return (
                            <Redirect to='/'/>
                        );
                    }
                    if (!this.state.company) {
                        return (<h1>Chargement des données de l'entreprise</h1>);
                    }
                    this.logger.trace(() => "this.state.company: " + JSON.stringify(this.state.company))
                    return (
                        <div id="hgr-home">

                            <NavigationPane company={this.state.company}
                                            currentRoom={this.state.currentRoom}
                                            user={this.state.user}
                                            currentVRoomLink={this.state.currentVRoomLink}
                                            onRoomActivate={(room, conferenceToken) => this.connectToRoom(room, conferenceToken)}
                                            onLogout={() => {
                                                userCtx.setAppState(undefined, {isAuthenticated: false} as Authentication);
                                                createBrowserHistory().push("/see-you-soon");
                                            }}
                                            updateStructure={this.state.updateStructure}
                                            updateStructureDone={() => this.setState({updateStructure: false})}
                            />

                            <div className={"room-view-content"}>
                                <div className={"hgr-header"}>
                                    <div className={"room-actions"}>

                                        <NotificationPane/>

                                    </div>
                                </div>
                            </div>
                            {
                                this.state.currentRoom &&
                                <MediaView user={this.state.user}
                                           currentRoom={this.state.currentRoom}
                                           conferenceToken={this.state.conferenceToken}
                                           onVideoConferenceJoined={videoConfInfo => this.onVRoomJoined(videoConfInfo)}
                                           onParticipantJoined={participantInfo => this.addUserInRoomIfNeeded(participantInfo)}
                                           onParticipantLeft={participantInfo => this.removeUserFromRoomIfNeeded(participantInfo)}
                                />

                            }
                            <div className={"loader-parent"}/>
                        </div>
                    );
                }}
            </UserContext.Consumer>
        );
    }

    async setApplicationState(user: User): Promise<HgrHomeState> {
        if (user) {
            if (user.company) {
                this.logger.debug("Getting the company from the api")
                let companyService = new CompanyService();
                let company = await companyService.getCompanyConfiguration(user.company);

                let roomToConnect: CompanyRoom | undefined;
                if (user.currentRoom != null) {
                    roomToConnect = company.sites?.map((site: CompanySite) => site.rooms)
                        .concat()
                        .flat()
                        .find(r => r?.ref === user.currentRoom);
                } else {
                    roomToConnect = company.sites?.map((site: CompanySite) => site.rooms)
                        .concat()
                        .flat()
                        .find(r => r?.ref === user.office);
                }

                if (roomToConnect) {
                    new WebsocketService().initWebSocket();
                    const roomToConnectConst = roomToConnect;
                    await companyService.enterRoom(roomToConnect, answer => {
                        user.currentRoom = roomToConnectConst.ref;
                        if (!roomToConnectConst.participants?.find(p => p.ref === user.ref)) {
                            roomToConnectConst.participants?.push(user);
                        }
                        this.setState({
                            conferenceToken: answer.conferenceToken,
                            currentRoom: roomToConnectConst,
                            user: user,
                        });
                    });

                    user.currentRoom = roomToConnect.ref;

                    return {
                        company: company,
                        currentRoom: roomToConnect,
                        user: user,
                        updateStructure: false,
                    };
                } else {
                    throw new Error("La salle de l'utilisateur n'a pu être trouvée.");
                }

            } else {
                throw new Error("Invalid State: the user doesn't have a company");
            }
        } else {
            return {} as HgrHomeState;
        }
    }

    connectToRoom = (room: CompanyRoom, conferenceToken: string | undefined) => {
        this.logger.info(`Moving to room: ${room?.ref}`);
        this.setState({currentRoom: room, conferenceToken: conferenceToken});
    };

    private updateRooms = (roomUpdate: RoomUpdateNotification | undefined) => {
        if (roomUpdate) {

            let company = this.state.company;
            let update = roomUpdate.room;
            let updateStructure: boolean = false;
            if (company && update) {
                let foundSite = company.sites?.find(site => site.ref === update?.siteRef);
                if (foundSite) {
                    let foundRoom = foundSite.rooms?.find(r => r.ref === update?.ref);
                    if (foundRoom) {
                        // Let's update it
                        foundRoom.state = update.state;
                        foundRoom.name = update.name;
                        if (!foundRoom.config) {
                            foundRoom.config = {};
                        }
                        foundRoom.config.viewMode = update.config?.viewMode;
                        foundRoom.config.cameraMode = update.config?.cameraMode;
                        foundRoom.config.nameMode = update.config?.nameMode;
                        foundRoom.config.startWithMicOn = update.config?.startWithMicOn;
                    } else {
                        // It's a new room
                        if (!foundSite.rooms) {
                            foundSite.rooms = [];
                        }
                        foundSite.rooms.push(update);
                        updateStructure = true;
                    }
                } else {
                    this.logger.warn(() => "The site for this room update doesn't exist in this UI. " + JSON.stringify(roomUpdate))
                }

                this.setState({company: company, updateStructure: updateStructure})
            }
        }

    }

    private updatePosition = (positionUpdate: RoomPositionUpdate | undefined) => {
        if (positionUpdate) {

            let company = this.state.company;
            if (company) {

                // Update rooms participants
                company.sites?.flatMap(s => s.rooms).forEach(room => {
                    if (room && positionUpdate.user) {

                        if (positionUpdate.newRoom === room.ref) {
                            if (!room.participants?.find(p => p.ref === positionUpdate.user?.ref)) {
                                room.participants?.push(positionUpdate.user);
                            }
                        } else {
                            let index = room.participants?.findIndex(p => p.ref === positionUpdate.user?.ref);
                            if (index !== undefined && index > -1) {
                                room.participants?.splice(index, 1);
                            }
                        }
                    }
                });

                let state = {company: company} as HgrHomeState;

                // update user
                if (positionUpdate.user && this.state.user.ref === positionUpdate.user?.ref) {
                    state.user = positionUpdate.user;
                }

                this.setState(state);
            }

        }
    }

    private allowEnteringTheRoom = (notification: RoomAccessAllowedNotification | undefined) => {
        if (notification) {
            let roomRef = notification.room;
            let room = this.state.company.sites?.flatMap(s => s.rooms).find(r => r?.ref === roomRef);
            if (room) {
                let nonNullRoom: CompanyRoom = room;
                this.notificationService.askForAction(notification,
                    () => this.companyService.enterRoom(nonNullRoom,
                        answer =>
                            this.connectToRoom(nonNullRoom, answer.conferenceToken)
                    ));
            }
        }
    }

    private addUserInRoomIfNeeded(participantInfo: ParticipantInfo) {
        let participants = this.state.currentRoom.participants;
        let matchingUser = participants?.find(u => u.email === participantInfo.email || u.nickName === participantInfo.displayName);
        if (!matchingUser) {
            let jitsiUser: User = {
              ref: `jitsi-${participantInfo.participantId}`,
              nickName: participantInfo.displayName,
              email: participantInfo.email,
              picture: participantInfo.avatarURL,
              name: participantInfo.displayName,
            };
            this.updatePosition({user: jitsiUser, newRoom: this.state.currentRoom.ref});
            this.notificationService.notifyUser(`Un invité ${participantInfo.displayName} vient de rentrer dans la salle.`, undefined, '/img/enter-room.png');
        } else {
            this.logger.debug(`User '${matchingUser.name}' found in room`);
        }
    }

    private removeUserFromRoomIfNeeded(participantInfo: { id: string }) {
        let participants = this.state.currentRoom.participants;
        let matchingUser = participants?.find(u => u.ref === `jitsi-${participantInfo.id}`);
        if (matchingUser) {
            this.updatePosition({user: matchingUser, previousRoom: this.state.currentRoom.ref});
            this.notificationService.notifyUser(`L'invité ${matchingUser.name} vient de quitter la salle.`);
        } else {
            this.logger.debug(`User '${participantInfo.id}' not found in room`);
        }
    }

    private onVRoomJoined(videoConfInfo: VideoConferenceInfo) {
        this.setState({
            currentVRoomLink: videoConfInfo.confLink,
        });
    }
}

export default HgrHome;

