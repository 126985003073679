import './LoginPage.scss';

import React, {createRef, FormEvent} from "react";
import {GoogleLoginButton, FacebookLoginButton, LinkedinLoginButton} from "./SocialLoginButton";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {UserService} from "services/user-service";
import {SocialUserProfile} from "components/model/hgr-user";
import {UserContext} from "components/utils/UserContext";
import {Redirect, RouteProps} from "react-router-dom";
import {Authentication} from "components/utils/SecurityContext";
import {Logger} from "typescript-logging";
import {LoggerFactory} from "../utils/ConfigLog4j";
import {User} from "hgr-api";
import {AppConfig} from "../config/constants";
import Register from "./Register";
import {Alert, Button, Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import {faArrowCircleDown, faEdit, faLock, faUser} from "@fortawesome/free-solid-svg-icons";

export type LoginPageState = {
    loginError: boolean,
    isLoggedIn: boolean,
    login?: string,
    password?: string,
    rememberMe?: boolean,
    createAccountModal: boolean,
    retrievePasswordModal: boolean,
    redirectToReferrer: boolean,
    from?: { pathname: string },
    loginMessage?: string,
}

type RouteState = {
    from?: { pathname: string },
    loginMessage?: string,
}

class LoginPage extends React.Component<RouteProps, LoginPageState> {

    private loginFormRef = createRef<HTMLDivElement>();

    userService: UserService = new UserService();
    private logger: Logger = LoggerFactory.getLogger("hgr.LoginPage");

    constructor(props: RouteProps) {
        super(props);
        let routeState = this.props.location?.state as RouteState;
        this.logger.info(() => "RouteState = " + JSON.stringify(routeState));
        this.state = {
            loginError: false,
            isLoggedIn: false,
            createAccountModal: false,
            retrievePasswordModal: false,
            redirectToReferrer: false,
            from: routeState?.from,
            loginMessage: routeState?.loginMessage,
        };
    }

    render() {
        if (this.state.isLoggedIn && this.state.from) {
            return <Redirect to={this.state.from}/>
        }
        return (
            <UserContext.Consumer>
                {
                    userCtx => {
                        return this.displayLoginPage(userCtx.setAppState)
                    }
                }
            </UserContext.Consumer>
        );
    }


    handleLogin = async (loginProvider: () => Promise<User>, updateUserInCtx: (user: User, auth: Authentication) => void) => {
        try {
            let userProfile = await loginProvider();
            let auth = {
                isAuthenticated: true,
                principal: {name: userProfile.name},
                credentials: {
                    // loginProviderToken: { provider: user.provider, token: user.token } 
                }
            } as Authentication;
            updateUserInCtx(userProfile, auth);
            this.setState({
                isLoggedIn: true,
            });
        } catch (e: any) {
            this.logger.error("erreur d'authentification", e);
            this.setState({
                loginError: true
            })
        }
    }

    handleHgrLogin = async (event: FormEvent, updateUserInCtx: (user: User, auth: Authentication) => void) => {
        event.preventDefault();
        await this.handleLogin(async () => await this.userService.handleLoginWithHGR(this.state.login, this.state.password, this.state.rememberMe), updateUserInCtx);
    }

    handleSocialLogin = async (user: SocialUserProfile, updateUserInCtx: (user: User, auth: Authentication) => void) => {
        await this.handleLogin(async () => await this.userService.handleLoginWithSocial(user), updateUserInCtx);
    }

    handleSocialLoginFailure = (err: any) => {
        this.userService.handleLoginFailure(err);
        this.setState({
            loginError: true
        });
    }

    private registerAccount = () => {
    };

    private displayLoginPage(updateUserInCtx: (user: User, auth: Authentication) => void,): React.ReactNode {
        if (this.state.isLoggedIn) {
            if (this.state.from) {
                return <Redirect to={this.state.from}/>;
            } else {
                return <Redirect to="/"/>;
            }
        }
        this.logger.trace(`Welcome message: ${this.state.loginMessage}.`);
        this.logger.trace(() => JSON.stringify(this.state));
        return (
            <Container>

                <Row style={{textAlign: 'center', verticalAlign: 'middle'}}>
                    <Col ref={this.loginFormRef}>
                        {this.state.loginMessage && this.displayLoginMessage()}
                        <img src="/logo.png" alt={"hologramme-logo"}/>
                        <h2 className="mb-4">
                            Se connecter avec
                        </h2>
                        <Container className={"shadow rounded p-4"}>
                            <Row className={"no-gutters w-100"}>
                                <Col md={6} className={"px-2"}>
                                    {this.getLocalLoginForm(updateUserInCtx)}
                                </Col>

                                <Row className={"no-gutters d-md-none py-4 w-100"}>
                                    <Col xs={5}><hr/></Col>
                                    <Button variant={"link"} className={"col text-center"}
                                            onClick={() => this.scrollDownOnXS_screens()}>
                                        <FontAwesomeIcon icon={faArrowCircleDown}/>
                                    </Button>
                                    <Col xs={5}><hr/></Col>
                                </Row>

                                <Col md={6} className={"px-2"}>
                                    <p>Si vous n'avez pas encore de compte, vous pouvez en créer un</p>
                                    <Container className={"create-account"}>
                                        <Row>
                                            <Col>
                                                <Button block onClick={this.createLocalAccount.bind(this)}>
                                                    <FontAwesomeIcon icon={faEdit}/> Créer un compte
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                    {
                                        AppConfig.ENVIRONMENT.socialLogin && this.displaySocialLoginButtons(updateUserInCtx)
                                    }
                                </Col>
                            </Row>
                            {this.state.loginError &&
                                <Row className={"my-4"}>
                                    <Col>
                                        <Alert variant={'danger'}>L'authentification a échouée.</Alert>
                                    </Col>
                                </Row>
                            }
                        </Container>

                        <Row className={"w-100 my-2"}>
                            <Col>
                                <span>
                                    Vous n'arrivez pas à vous connecter?
                                    Avez vous <Button variant={"link"}
                                                      onClick={this.retrievePassword.bind(this)}>
                                    oublié votre mot de passe?
                                    </Button>
                                </span>
                            </Col>
                        </Row>
                    </Col>
                    <Register show={this.state.createAccountModal}
                              onClose={() => this.setState({createAccountModal: false})}
                              onRegister={this.registerAccount.bind(this)}/>
                </Row>
            </Container>
        );
    }

    private scrollDownOnXS_screens() {

        window.scrollTo({
            top: this.loginFormRef.current?.clientHeight,
            behavior: "smooth"
        });
    }

    private displayLoginMessage() {
        return <Alert variant={"info"}>{this.state.loginMessage}</Alert>;
    }

    private displaySocialLoginButtons(updateUserInCtx: (user: User, auth: Authentication) => void) {
        return <>
            <Row>
                <Col>
                    <hr/>
                </Col>
                <Col md={"auto"}>OU</Col>
                <Col>
                    <hr/>
                </Col>
            </Row>
            <Container className={"social-login-buttons"}>
                <Col>
                    <Row>
                        <Col>
                            <GoogleLoginButton block
                                               provider='google'
                                               appId='743693902497-g6ulefbpp8akq0actit2o2qj8c8ptpoe.apps.googleusercontent.com'
                                               onLoginSuccess={(u) => this.handleSocialLogin(u, updateUserInCtx)}
                                               onLoginFailure={this.handleSocialLoginFailure}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FacebookLoginButton block
                                                 provider='facebook' appId='563677614355517'
                                                 onLoginSuccess={(u) => this.handleSocialLogin(u, updateUserInCtx)}
                                                 onLoginFailure={this.handleSocialLoginFailure}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>

                            <LinkedinLoginButton block
                                                 provider='linkedin' appId='789cgk0zwqfob6'
                                                 onLoginSuccess={(u) => this.handleSocialLogin(u, updateUserInCtx)}
                                                 onLoginFailure={this.handleSocialLoginFailure}/>
                        </Col>
                    </Row>
                </Col>
            </Container>
        </>;
    }

    private getLocalLoginForm(updateUserInCtx: (user: User, auth: Authentication) => void) {
        return <Form onSubmit={(e) => this.handleHgrLogin(e, updateUserInCtx)}>
            <Form.Group className="mb-3">
                <Form.Label>Identifiant ou email</Form.Label>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text><FontAwesomeIcon icon={faUser}/></InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control type="text" onChange={(e) => this.setState({login: e.target.value})}/>
                </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Mot de passe</Form.Label>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text><FontAwesomeIcon icon={faLock}/></InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control type={"password"} onChange={(e) => this.setState({password: e.target.value})}/>
                </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Check type={"checkbox"} label='Se souvenir de moi'
                            onChange={(e) => this.setState({rememberMe: e.target.checked})}/>
            </Form.Group>

            <Button type="submit">Se connecter</Button>
        </Form>;
    }

    private createLocalAccount() {
        this.setState({createAccountModal: true});
    }

    private retrievePassword() {
        this.setState({retrievePasswordModal: true})
    }
}

export default LoginPage;